import React from "react";
import "./Hero.css";

const Hero = (props) => {
  return (
    <div className="man">
      <img src={props.image} alt="background" />
      <p>{props.description}</p>
    </div>
  );
};

export default Hero;
