import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./location.css";

export default class Location extends Component {
  render() {
    return (
      <div className="content top location">
        <Helmet>
          <title>Gorilla remarkable location</title>
        </Helmet>
        <p style={{ display: "none" }}>
          Browse and experince different tours you can get in Rwanda.
        </p>
        <iframe src="https://maps.google.com/maps?q=-1.50763,29.60662&z=8&output=embed"></iframe>
      </div>
    );
  }
}
