import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal";
import Book from "../../../Components/Book/Book";
import Rwandair from "../departure Images/rwandair.jpg";
import "../TourPage.css";
import Paragraph from "../../../Components/Paragraph/Paragraph";

import Akagera_transit_lodge_2 from "../hotels_lodges_partners/akagera_transit_lodge/akagera_transit (2).jpg";

import monkey_track from "./images/goldenMonkey_tracking2.jpg";
import gorilla_track from "./images/gorilla_tracking1.jpg";

import Amarembo_gorilla_lodge_5 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (5).jpg";
import Amarembo_gorilla_lodge_4 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (4).jpg";

import games_safari from "./images/natParks3.jpg";

export default function Days5Rwanda() {
  const [book, setBook] = useState(false);
  function openBooking() {
    setBook(true);
  }
  return (
    <div className="mainTourPage top">
      <Book
        handleClose={() => setBook(false)}
        book={book}
        selected="3 days tour"
      />
      <h1>5 DAYS GORILLA TRACKING AND GAME SAFARI</h1>
      <hr />
      <button onClick={() => openBooking()}>BOOK NOW</button>
      <h3>Day 1 - ARRIVAL</h3>
      <Paragraph>
        After arriving at Kigali internal airport, you will meet our driver
        guide and transfer to Musanze in KINIGI. Upon arriving at the lodge, we
        will have a rest waiting for an adventure on the following day.
        <br />
        <br />
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong> in Kinigi
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="Rwandair" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_4}
            alt="amarembo gorilla logde"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 2 - GORILLA TRACKING IN VOLCANOES NATIONAL PARK</h3>
      <Paragraph className="">
        We will wake up in the morning as usual for breakfast at 6 a.m. after
        completion of breakfast we will drive to volcanoes park reception for
        briefings. Visitors get divided into the groups as one group is not more
        than 8 people. After briefing we start gorilla tracking at 8:30 a.m.
        through park dense vegetations in search of gorillas. We will meet also
        a group of experienced trackers to locate their whereabout. Gorilla
        trekking takes between 2 hours and 7 hours including one magical hour
        you spend with gorillas taking images of them as they are playing,
        mothers nursing their kids. Juveniles and silverbacks playing together.
        After spending this one magical hour we will drive back to the lodge for
        lunch.
      </Paragraph>
      <br />
      <Paragraph>
        During afternoon at 3:30 p.m. we will drive to DIAN Fossey Gorilla Fund
        to meet the scientists. These gorilla scientists will share with us
        about gorilla lives deeply and their significance role for protecting
        mountain gorillas. Afterward we will drive back to the lodge for rest.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong> in Kinigi
      </Paragraph>
      <br />
      <div className="grid grid-cols-4 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={gorilla_track} alt="gorilla" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_5}
            alt="amarembo gorilla logde"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 3 - GOLDEN MONKEY TRACKING IN VOLCANOES NATIONAL PARK</h3>
      <Paragraph>
        We will again wake up in the morning for breakfast and then we drive
        back to volcanoes park reception at 7 a.m. for briefings from guide.
        Golden monkey tacking takes between 2 hours and 4 hours including one
        hour you spend with monkeys taking images, enjoying their play and
        movement as they run often on the top of the trees. Afterward we drive
        back to the lodge for lunch.
        <br />
        <br />
        At 1:30 p.m. transfer to AKAGERA national park. 6 hours driving from
        MUSANZE to AKAGERA national park. Upon arriving at the lodge, we will
        have a rest waiting for game safari on the following day.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AKAGERA TRANSIT LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={monkey_track} alt="golden monkey" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Akagera_transit_lodge_2}
            alt="akagera transit lodge"
          />
        </Fade>
      </div>
      <br />

      <br />
      <h3>Day 4 - GAME SAFARI IN AKAGERA NATIONAL PARK</h3>
      <Paragraph>
        We will wake up very early in the morning at 5 a.m. for breakfast and
        driving to AKAGERA park reception for briefings from AKAGERA guide. Park
        opens from 6 a.m. and closes 6 p.m. we will experience wildlife
        profoundly. In particular we will experience BIG FIVE like lion,
        elephant, leopard, buffalo and rhinos. A part from big five we will also
        experience other wildlife like giraffes, zebras, hippos and many more.
        We will enjoy the beauty of the lakes found in AKAGERA like lake IHEMA
        and RWANYAKIZINGA. Around noon we will drive to the Picnic area for
        lunch and drinks. After completion of lunch, we will keep on our safari
        until sunset. After taking the images of sunset we will drive back to
        the lodge for rest.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AKAGERA TRANSIT LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={games_safari} alt="golden monkey" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Akagera_transit_lodge_2}
            alt="akagera transit lodge"
          />
        </Fade>
      </div>
      <br />

      <br />
      <h3>Day 5 - DEPARTURE</h3>
      <Paragraph>
        We will wake up in the morning for breakfast as usual after breakfast we
        will get enough moment of preparing our stuffs and relax as we will be
        waiting for the flight at night. In the evening, we will dive to Kigali
        at international airport for flight.
      </Paragraph>
      <br />
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="rwandair" />
        </Fade>
      </div>
      <br />
      <button onClick={() => openBooking()}>BOOK NOW</button>
    </div>
  );
}
