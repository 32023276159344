import React from "react";
import { Helmet } from "react-helmet";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import Cards from "../../Components/Common/Cards";
import Hero from "../../Components/Common/HeroExplore";
import "./Explore.css";
import golden_monkey from "./Images/Golden_monkey.jpg";
import hero from "./Images/activities.png";
import bird_watching2 from "./Images/bird_watching1.jpg";
import biso_hike from "./Images/bis0_hike.jpg";
import boat_trip from "./Images/boat_trip.jpg";
import canopy_walk from "./Images/canopy_walk.jpg";
import chimpanzee_tracking1 from "./Images/chimpanzee_tracking.jpg";
import chimpanzee_tracking2 from "./Images/chimpanzee_tracking2.jpg";
import dianFossey from "./Images/dianFossey.jpg";
import game_drive from "./Images/game_drive.jpg";
import gorilla_tracking from "./Images/gorilla_tracking.jpg";
import kali_hike from "./Images/kali_hike.jpg";
import nationalParks from "./Images/nationalParks.jpg";

const akageraList = [
  {
    image: game_drive,
    title: "Game Driving",
    description: (
      <p>
        Experience a thrilling ride looking fr wild game in a comfortable and
        safe vehicle in Akagera National Park.
      </p>
    ),
    link: "/explore/akagera/game driving",
  },
  {
    image: boat_trip,
    title: "Boat Trip",
    description: (
      <p>
        For the more adventurous travelers interested in something different
        from the usual safari experience in Akagera park, you can opt to take a
        boat trip on the beautiful Lake Ihema.
      </p>
    ),
    link: "/explore/akagera/boat trip",
  },
];

const virungaList = [
  {
    image: gorilla_tracking,
    title: "Gorilla Tracking",
    description: (
      <p>
        The unique opportunity to see gorillas in their natural habitat is
        unforgettable, some even say life changing.
      </p>
    ),
    link: "/explore/volcanoes/gorilla tracking",
  },
  {
    image: chimpanzee_tracking1,
    title: "Chimpanzee Tracking",
    description: (
      <p>
        It is common to hear the chimpanzees before seeing them, with their
        vocalisations reverberating through the forest as they call to each
        other in a sort of bonding ritual.
      </p>
    ),
    link: "/explore/volcanoes/chimpanzee tracking",
  },
  {
    image: golden_monkey,
    title: "Golden Monkey Tracking",
    description: (
      <p>
        Golden monkeys are known to have unique characters and are a rare
        species that are endangered at the moment and they are currently found
        in the Virunga Mountains in Rwanda.
      </p>
    ),
    link: "/explore/volcanoes/golden monkey tracking",
  },
  {
    image: kali_hike,
    title: "Kalisimbi Hiking",
    description: (
      <p>
        Hiking to the top of Mt Karismbi is one of the key tourism activities
        you should not miss on your Rwanda safari to Volcanoes National Park.
        Rising at an elevation of about 4507m above sea level is MT. Karisimbi.
      </p>
    ),
    link: "/explore/volcanoes/kalisimbi hiking",
  },
  {
    image: biso_hike,
    title: "Bisoke Hiking",
    description: (
      <p>
        Hiking Bisoke also known as “Visoke” is an adventurous and exciting
        activity that can be done by even inexperienced hikers.
      </p>
    ),
    link: "/explore/volcanoes/bisoke hiking",
  },
  {
    image: dianFossey,
    title: "Dian Fossey's Grave Visiting",
    description: (
      <p>
        Located in a beautiful meadow nestled between the Karisimbi and Visoke
        volcanoes are the graves of Dian Fossey and some of her beloved research
        gorillas.
      </p>
    ),
    link: "/explore/volcanoes/dian fossey tomb",
  },
];

const nyungweList = [
  {
    image: chimpanzee_tracking2,
    title: "Chimpanzee Tracking",
    description: (
      <p>
        Nyungwe is an ideal destination for lovers of primates and most visitors
        will go for at least one tracking experience, the most popular of which
        is chimpanzee tracking.
      </p>
    ),
    link: "/explore/nyungwe/chimpanzee tracking",
  },
  {
    image: bird_watching2,
    link: "/explore/nyungwe/birdwatching",
    title: "Bird Watching",
    description: (
      <p>
        Nyungwe Forest National Park is a delight for birdwatchers, with over
        300 bird species recorded, including 27 species endemic to the Albertine
        Rift.
      </p>
    ),
  },
  {
    image: canopy_walk,
    title: "Canopy Walkway",
    description: (
      <p>
        Suspended above a ravine in the lush montane rainforest of Nyungwe
        National Park, the canopy walkway provides an exhilarating perspective
        on the ancient treetops and wildlife.
      </p>
    ),
    link: "/explore/nyungwe/canopy walkway",
  },
];

const ExploreVirunga = () => {
  return (
    <main className="mainExplore top">
      <Helmet>
        <title>Virunga</title>
      </Helmet>
      <div className="hero">
        <Hero image={hero} description={"Explore Our Beautiful Attractions"} />
        <Link to="content" smooth={true}>
          <i className="fa fa-caret-down"></i>
        </Link>
      </div>
      <div className="content explore">
        <Cards
          list={[
            {
              image: nationalParks,
              title: "National Parks",
              description: (
                <p>
                  Suspended above a ravine in the lush montane rainforest of
                  Nyungwe National Park, the canopy walkway provides an
                  exhilarating perspective on the ancient treetops and wildlife.
                </p>
              ),
              link: "/explore/national parks",
            },
          ]}
        />
        <Cards list={virungaList} />
        <Link to="explore" smooth={true} className="navigation">
          <Fade bottom big>
            <div className="navigation bg">
              <ul>
                <li className="">
                  <NavLink to="/explore/akagera" id="akagera">
                    Akagera
                  </NavLink>
                </li>
                <li className="">
                  <NavLink to="/explore/nyungwe" id="nyungwe">
                    Nyungwe
                  </NavLink>
                </li>
                <li className="active">
                  <NavLink to="/explore/volcanoes" id="virunga">
                    Volcanoes
                  </NavLink>
                </li>
              </ul>
            </div>
          </Fade>
        </Link>
      </div>
    </main>
  );
};

export default ExploreVirunga;
