import { Helmet } from "react-helmet";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal/Fade";
import Hero from "../../Components/Common/HeroAboutUs";

import IzabeOlivier from "../../Images/Gorilla logo.png";
import About0 from "./Images/about-0.jpg";
import About1 from "./Images/about-1.jpg";
import About2 from "./Images/about-2.jpg";
import About3 from "./Images/about-3.png";
import "./aboutus.css";

export default function AboutUs() {
  return (
    <div className="content aboutus top">
      <Helmet>
        <title>About us</title>
      </Helmet>
      <p style={{ display: "none" }}>
        Browse and experince different tours you can get in Rwanda.
      </p>
      <Fade bottom>
        <div>
          <Hero image={About3} description={""} />
        </div>
        <div className="owner">
          <div className="info">
            <LazyLoadImage
              effect="blur"
              src={IzabeOlivier}
              alt="gorilla remarkable"
            />
            {/* <h3>Oliver Izabe - Tour operator</h3> */}
          </div>
          <div className="description">
            <p className="antialiased text-base font-light leading-7">
              Gorilla Remarkable Tours is a leading travel company in Rwanda
              certified by Rwanda Development Board. Gorilla Remarkable Tours
              provides safaris in Rwanda. We provide about Accommodations, car
              hire, camping, birdwatching, gorilla Tracking, Golden monkey
              Tracking, Chimpanzee Trekking & canopy walkway, Game Driving, Boat
              safari, hiking mountains, Community Tour for cultural Activity,
              Air tickets, city Tour, Tea & coffee Tour. we committed to make
              your Dreams come true through providing excellent services and
              sharing Authentic experiences to our clients, we have professional
              Team and Staff who are knowledgeable with flora, fauna, culture,
              history of Rwanda. beyond that our Team has expertise with
              organising and planning tours.
            </p>
          </div>
        </div>
        <div className="w-[90%] md:w-4/5 mx-auto block md:flex items-start mt-10">
          <div className="text-gray basis-3/5 antialiased text-base font-light leading-7">
            <div className="my-4  pt-6">
              <h1 className="mb-4 text-2xl font-bold text-primary">
                Why choose us?
              </h1>
              <p>
                Your safety and security is our number one priority, we make
                sure that you leave Rwanda with ultimate and Authentic
                experience with Rwanda wildlife.
              </p>

              <div className="flex items-center my-4">
                <AiFillSafetyCertificate className="mr-2 text-secondary" />
                <p>Our knowledge</p>
              </div>

              <div className="flex items-center my-4">
                <AiFillSafetyCertificate className="mr-2 text-secondary" />
                <p>Experienced Staff </p>
              </div>

              <div className="flex items-center my-4">
                <AiFillSafetyCertificate className="mr-2 text-secondary" />
                <p>Professional guides </p>
              </div>

              <div className="flex items-center my-4">
                <AiFillSafetyCertificate className="mr-2 text-secondary" />
                <p>Passionate about Rwanda wildlife </p>
              </div>

              <div className="flex items-center my-4">
                <AiFillSafetyCertificate className="mr-2 text-secondary" />
                <p>Value for money </p>
              </div>
            </div>

            <div className="my-4 pt-6">
              <h1 className="mb-2 text-2xl font-bold text-primary">
                Our mission and vision.
              </h1>
              <p>
                Promoting domestic and international Tourism in Rwanda Country
                and providing excellent services and sharing Authentic
                experiences to our clients.
              </p>
              <p className="my-2">
                Providing & Contributing in wildlife conservation and educating
                young people about environment protection in terms of promoting
                sustainable conservation and preparing next future generation
                with conservation
              </p>
            </div>

            <div>
              <div className="phone:block flex items-center">
                <Fade bottom>
                  <LazyLoadImage
                    effect="blur"
                    src={About0}
                    alt="Olivier Izabe"
                    className="phone:w-full w-[90%]"
                  />
                </Fade>
                <Fade bottom>
                  <LazyLoadImage
                    effect="blur"
                    src={About1}
                    alt="Olivier Izabe"
                    className="phone:w-full w-[50%] "
                  />
                </Fade>
              </div>
            </div>
            <div>
              <div className="phone:block flex flex-row items-center justify-between">
                <Fade bottom>
                  <LazyLoadImage
                    effect="blur"
                    src={About2}
                    alt="Olivier Izabe"
                    className="phone:w-full w-[90%]"
                  />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
