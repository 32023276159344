import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal";
import Book from "../../../Components/Book/Book";
import Gorilla from "./images/gorilla.jpg";
import Rwandair from "../departure Images/rwandair.jpg";
import "../TourPage.css";
import Amarembo_gorilla_lodge_1 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (1).jpg";
import Amarembo_gorilla_lodge_2 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (3).jpg";
import Paragraph from "../../../Components/Paragraph/Paragraph";
import monkey_tracking from "./images/amarembo (2).jpg";

export default function Days3() {
  const [book, setBook] = useState(false);
  function openBooking() {
    setBook(true);
  }
  return (
    <div className="mainTourPage top">
      <Book
        handleClose={() => setBook(false)}
        book={book}
        selected="3 days tour"
      />
      <h1>3 DAYS GORILLA TRACKING IN RWANDA</h1>
      <hr />
      <button onClick={() => openBooking()}>BOOK NOW</button>
      <h3>Day 1 - ARRIVAL</h3>
      <Paragraph>
        After arriving at Kigali internal airport, you will meet our driver
        guide and transfer to Musanze in KINIGI. Upon arriving at the lodge, we
        will have a rest waiting for an adventure on the following day.
        <br />
        <br />
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong> in Kinigi
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="Rwandair" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_1}
            alt="amarembo gorilla logde"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 2 - GORILLA TRACKING IN VOLCANOES NATIONAL PARK</h3>

      <Paragraph className="">
        We will wake up in the morning as usual for breakfast at 6 a.m. after
        completion of breakfast we will drive back to volcanoes park reception
        for briefings. Visitors get divided into the groups as one group is not
        more than 8 people. After briefing we start gorilla tracking at 8:30
        a.m. through park dense vegetations in search of gorillas. We will meet
        also a group of experienced trackers to locate their whereabout. Gorilla
        trekking takes between 2 hours and 7 hours including one magical hour
        you spend with gorillas taking images of them as they are playing,
        mothers nursing their kids. Juveniles and silverbacks playing together.
        After spending this one magical hour we will drive back to the lodge for
        lunch.
      </Paragraph>

      <br />
      <Paragraph>
        During afternoon at 3:30 p.m. we will drive to DIAN Fossey Gorilla Fund
        to meet the scientists. These gorilla scientists will share with us
        about gorilla lives deeply and their significance role for protecting
        mountain gorillas. Afterward we will drive back to the lodge for rest.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong> in Kinigi
      </Paragraph>
      <br />
      <div className="grid grid-cols-4 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Gorilla} alt="gorilla" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_2}
            alt="amarembo gorilla logde"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 3 - GOLDEN MONKEY TRACKING AND DEPARTURE</h3>
      <Paragraph>
        We will again wake up in the morning for breakfast and then we drive
        back to volcanoes park reception at 7 a.m. for briefings from guide.
        Golden monkey tacking takes between 2 hours and 4 hours including one
        hour you spend with monkeys taking images, enjoying their play and
        movement as they run often on the top of the trees. Afterward we drive
        back to the lodge for lunch.
        <br />
        <br />
        At 4 p.m. transfer to Kigali international airport for flight. 3 hours
        driving.
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={monkey_tracking}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="rwandair" />
        </Fade>
      </div>
      <button onClick={() => openBooking()}>BOOK NOW</button>
    </div>
  );
}
