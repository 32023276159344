import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal";
import Book from "../../../Components/Book/Book";
import Rwandair from "../departure Images/rwandair.jpg";
import "../TourPage.css";
import Paragraph from "../../../Components/Paragraph/Paragraph";
import monkey_tracking_1 from "./images/-1790716789.jpg";

import Rushaga_gorilla_lodge_7 from "../hotels_lodges_partners/rushaga_gorilla_lodge/rushaga (7).jpg";
import Rushaga_gorilla_lodge_6 from "../hotels_lodges_partners/rushaga_gorilla_lodge/rushaga (6).jpg";

import Gorilla_tracking_2 from "./images/gorTracking2.jpg";

import Amarembo_gorilla_lodge_7 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (7).jpg";
import Amarembo_gorilla_lodge_6 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (6).jpg";

import Transfer_car_1 from "../safari cars/tourcars.jpg";

import Akagera_transit_lodge_6 from "../hotels_lodges_partners/akagera_transit_lodge/akagera_transit (6).jpg";
import Akagera_transit_lodge_5 from "../hotels_lodges_partners/akagera_transit_lodge/akagera_transit (5).jpg";
import Akagera_transit_lodge_4 from "../hotels_lodges_partners/akagera_transit_lodge/akagera_transit (4).jpg";

import Heaven_boutique_hotel_8 from "../hotels_lodges_partners/heaven_boutique_and _restaurant_hotel/heaven_boutique (8).jpg";

import hiking_bisoke from "./images/bisoHike.jpg";

import game_driving from "./images/gamedrive.jpg";
import game_driving1 from "./images/game_drive1.jpg";

import Kigali_city_tour from "./images/kigali city tour.jpg";

export default function Days9() {
  const [book, setBook] = useState(false);
  function openBooking() {
    setBook(true);
  }
  return (
    <div className="mainTourPage top">
      <Book
        handleClose={() => setBook(false)}
        book={book}
        selected="3 days tour"
      />
      <h1>9 DAYS IN RWANDA AND UGANDA</h1>
      <hr />
      <button onClick={() => openBooking()}>BOOK NOW</button>
      <h3>Day 1 - ARRIVAL</h3>
      <Paragraph>
        After arriving at Kigali internal airport, you will meet our driver
        guide and transfer to the hotel in KIGALI for rest waiting for an
        adventure on the following day.
        <br />
        <br />
        Tourist hotel:{" "}
        <strong className="uppercase">
          Heaven Boutique and restaurant hotel
        </strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="Rwandair" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Heaven_boutique_hotel_8}
            alt="heaven boutique hotel"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 2 - KIGALI CITY TOUR AND TRANSFER TO AKAGERA</h3>
      <Paragraph className="">
        We will wake up in the morning and have breakfast. Afterward we will
        start Kigali city tour at 9 a.m. Kigali city tour takes you through an
        amazing discovery of Rwanda capital. Kigali is the commercial center of
        Rwanda with most important political offices in the country. Kigali is
        also one of cleanest and safest cities in Africa. Walk through city
        center will lead you to impressive 5-star hotels and high-end
        restaurant, and bars. We will also visit KIMIRONKO market where the
        crafted items sold. Afterward we will drive to Kigali GENOCIDE memorial
        to witness dark history and tragedy Rwanda underwent and how the country
        renewed through reconciliation. After Kigali city tour, we will have our
        lunch at one of the best restaurants in Kigali.
      </Paragraph>
      <Paragraph>
        During in the evening around 3 p.m. we will transfer to AKAGERA transit
        lodge which is near by the entrance of AKAGERA national park. Once we
        arrive at lodge, we will have a rest waiting for game safari on the
        following day.
      </Paragraph>
      <br />

      <br />
      <Paragraph>
        Tourist lodge: <strong>AKAGERA TRANSIT LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-4 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Kigali_city_tour}
            alt="kigali city tour"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Akagera_transit_lodge_4}
            alt="akagera transfer"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 3 - GAME SAFARI IN AKAGERA NATIONAL PARK</h3>
      <Paragraph>
        We will wake up very early in the morning at 5 a.m. for breakfast and
        drive to AKAGERA park reception for briefings from AKAGERA guide. Park
        opens from 6 a.m. and closes at 6 p.m. we will experience wildlife
        profoundly. In particular we will experience BIG FIVE like lion,
        elephant, leopard, buffalo and rhinos. A part from big five we will also
        experience other wildlife like giraffes, zebras, hippos and many more.
        We will enjoy the beauty of the lakes found in AKAGERA like lake IHEMA
        and RWANYAKIZINGA. Around noon we will drive to the Picnic area for
        lunch and drinks. After completion of lunch, we will keep on our safari
        until sunset. After taking the images of sunset we will drive back to
        the lodge for rest.
        <br />
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AKAGERA TRANSIT LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={game_driving} alt="game drive" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Akagera_transit_lodge_5}
            alt="akagera transit lodge"
          />
        </Fade>
      </div>
      <br />

      {/* Start 4 days tour*/}

      <br />
      <h3>Day 4 - GAME SAFARI IN AKAGERA NATIONAL PARK</h3>
      <Paragraph>
        Again, we will wake up very early in the morning for breakfast and
        driving back to AKAGERA national park for game safari. Executing one
        more day in AKAGERA national park is really important to the visitors in
        order to be able to meet all wildlife and feeling connections about
        wildlife as they meet them frequently. Around noon we drive back to
        MIHINDI which is picnic area in AKAGERA national park. After completion
        of our lunch, we will curry on our safari until unset again. Afterward
        we will drive back to the lodge for rest waiting for transfer on
        following day.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AKAGERA TRANSIT LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={game_driving1} alt="game driving" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Akagera_transit_lodge_6}
            alt="akagera transit lodge"
          />
        </Fade>
      </div>
      <br />

      {/* End 4 days tour*/}

      {/* Start 5 days tour*/}

      <br />
      <h3>Day 5 - TRANSFER TO VOLCANOES NATIONAL PARK</h3>
      <Paragraph>
        We will wake up in the morning for breakfast and packing lunch and
        water. After completion of breakfast, we will drive to Musanze through
        Kigali. 6 hours driving from AKAGERA to MUSANZE. Upon arrival we will
        have a rest waiting for another adventure on the following day.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Transfer_car_1} alt="safari car" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_6}
            alt="amarembo gorilla lodge"
          />
        </Fade>
      </div>
      <br />

      {/* End 5 days tour*/}

      {/* Start 6 days tour*/}

      <br />
      <h3>Day 6 - HIKING BISOKE VOLCANO</h3>
      <Paragraph>
        We will have our early morning breakfast and packing our lunch and
        water. Afterwards we will drive to volcanoes park reception at 7 a.m.
        for briefing from guide. After briefing we will start hiking around 8:30
        a.m. BISOKE mountain is a home to mount gorillas with 3,711 m of
        elevation. Round trip takes about 6 hours. We will spend 6 hours
        experiencing biodiversity as we will be executing hiking. After
        completion of hiking, we will drive back to lodge for rest.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={hiking_bisoke}
            alt="bisoke hiking"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_7}
            alt="amarembo gorilla lodge"
          />
        </Fade>
      </div>
      <br />

      {/* End 6 days tour*/}

      {/* Start 7 days tour*/}

      <br />
      <h3>Day 7 - GOLDEN MONEY TRACKING AND TRANSFER TO UGANDA</h3>
      <Paragraph>
        We will wake up in the morning for breakfast and then after we will
        drive back to volcanoes park reception for briefings from guide. After
        briefings we drive to the national park at the starting point. We will
        go through park vegetations in search of monkeys as they move always on
        the top of the trees. we will meet the group of trackers to show us
        their whereabout. Golden monkeys tracking takes between 2 hours and 4
        hours including one hour you spend with them taking images. By 12 p.m.
        we will leave the national park going back to the lodge for lunch.
      </Paragraph>

      <Paragraph>
        After completion of lunch, we will transfer to UGANDA IN BWINDI forest.
        Once we arrive at the lodge, we will have a rest waiting for gorilla
        tracking on the following day.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>RUSHAGA GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={monkey_tracking_1}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Rushaga_gorilla_lodge_6}
            alt="rushaga gorilla lodge"
          />
        </Fade>
      </div>
      <br />

      {/* End 7 days tour*/}

      {/* Start 8 days tour*/}

      <br />
      <h3>Day 8 - GORILLA TRACKING IN BWINDI FOREST</h3>
      <Paragraph>
        We will wake up in the morning as usual for breakfast at 6 a.m. after
        completion of breakfast we will drive to BWINDI park reception for
        briefings. Visitors get divided into the groups as one group is not more
        than 8 people. After briefing we start gorilla tracking through park
        dense vegetations in search of gorillas. We will meet also a group of
        experienced trackers to locate their whereabout. Gorilla trekking takes
        between 2 hours and 7 hours including one magical hour you spend with
        gorillas taking images of them as they are playing, mothers nursing
        their kids. Juveniles and silverbacks playing together. After spending
        this one magical hour we will drive back to the lodge for lunch.
      </Paragraph>

      <br />
      <Paragraph>
        Tourist lodge: <strong>RUSHAGA GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Gorilla_tracking_2}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Rushaga_gorilla_lodge_7}
            alt="rushaga gorilla lodge"
          />
        </Fade>
      </div>
      <br />

      {/* End 8 days tour*/}

      {/* Start 9 days tour*/}

      <br />
      <h3>Day 9 - DEPARTURE</h3>
      <Paragraph>
        We will wake up in the morning for breakfast and packing lunch and water
        as usual and then after that we will drive back to RWANDA in KIGALI at
        international airport, 5 hours driving from RUSHAGA to KIGALI.
      </Paragraph>
      <br />
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="rwandair" />
        </Fade>
      </div>
      <br />

      {/* End 9 days tour*/}

      <button onClick={() => openBooking()}>BOOK NOW</button>
    </div>
  );
}
