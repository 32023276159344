import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal";
import Book from "../../../Components/Book/Book";
import Gorilla from "./images/gorilla1.jpg";
import Rwandair from "../departure Images/rwandair.jpg";
import "../TourPage.css";
import Paragraph from "../../../Components/Paragraph/Paragraph";

import Rushaga_gorilla_lodge_3 from "../hotels_lodges_partners/rushaga_gorilla_lodge/rushaga (3).jpg";
import Rushaga_gorilla_lodge_4 from "../hotels_lodges_partners/rushaga_gorilla_lodge/rushaga (4).jpg";

import Transfer_car_1 from "../safari cars/tourcars.jpg";

import monkey_track from "./images/645457494.jpg";

import elephant_hub_lodge_7 from "../hotels_lodges_partners/elephant_hab_lodge/elephant_hab (7).jpg";
import elephant_hub_lodge_8 from "../hotels_lodges_partners/elephant_hab_lodge/elephant_hab (8).jpg";

export default function Days5Uganda() {
  const [book, setBook] = useState(false);
  function openBooking() {
    setBook(true);
  }
  return (
    <div className="mainTourPage top">
      <Book
        handleClose={() => setBook(false)}
        book={book}
        selected="3 days tour"
      />
      <h1>5 DAYS GORILLA TRACKING AND GAME SAFARI</h1>
      <hr />
      <button onClick={() => openBooking()}>BOOK NOW</button>
      <h3>Day 1 - ARRIVAL</h3>
      <Paragraph>
        Early in the morning arriving at Entebbe international airport, you will
        meet with our drive guide and transfer to Bwindi national park. 10 hours
        driving from Entebbe international airport to Bwindi Forest in RUSHAGA
        sector. We will pack some snacks, lunch and water before leaving KAMPALA
        city. Its advisable to arrive at Entebbe airport very early in the
        morning as it is long drive from Entebbe to BWINDI. Upon arriving at the
        lodge in RUSHAGA we will have a rest waiting for an adventure on the
        following day.
        <br />
        <br />
        Tourist lodge: <strong>RUSHAGA GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="Rwandair" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Rushaga_gorilla_lodge_3}
            alt="amarembo gorilla logde"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 2 - GORILLA TRACKING IN BWINDI NATIONAL PARK</h3>
      <Paragraph className="">
        We will wake up in the morning as usual for breakfast at 6 a.m. and
        packing lunch and water. After completion of breakfast, we will drive to
        BWINDI park reception for briefings at 7 a.m. Visitors get divided into
        the groups as one group is not more than 8 people. After briefing at 8
        a.m. we start gorilla tracking through park dense vegetations in search
        of gorillas. We will meet also a group of experienced trackers to locate
        their whereabout. Gorilla trekking takes between 2 hours and 7 hours
        including one magical hour you spend with gorillas taking images of them
        as they are playing, mothers nursing their kids. Juveniles and
        silverbacks playing together. After spending this one magical hour we
        will drive back to the lodge for rest.
      </Paragraph>
      <br />

      <br />
      <Paragraph>
        Tourist lodge: <strong>RUSHAGA GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-4 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Gorilla} alt="gorilla" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Rushaga_gorilla_lodge_4}
            alt="amarembo gorilla logde"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 3 - TRANSFER TO QUEEN ELIZABETH NATIONAL PARK</h3>
      <Paragraph>
        We will wake up in the morning for breakfast and packing lunch and
        water. After completion of breakfast, we drive to Queen Elizabeth
        national park at elephant hab lodge. 5 hours driving from RUSHAGA sector
        to KASESE district. Upon arriving at the lodge, we will have a rest
        waiting for adventure on the following day.
        <br />
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>ELEPHANT HAB LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Transfer_car_1}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={elephant_hub_lodge_7}
            alt="elephant hub logde"
          />
        </Fade>
      </div>
      <br />

      <br />
      <h3>Day 4 - GAME SAFARI IN QUEEN ELIZABETH NATIONAL PARK</h3>
      <Paragraph>
        We will wake up very early in the morning for breakfast and packing
        lunch and water. After completion of breakfast, we drive to the national
        park for game safari. We will spend 6 hours in the park experiencing
        majestic wildlife like, lions, elephants, buffaloes, leopard, Nile
        crocodile, hippos, Topi, waterbuck and many more. After game safari we
        drive back to the lodge for rest.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>ELEPHANT HAB LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={monkey_track} alt="golden monkey" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={elephant_hub_lodge_8}
            alt="elephant hub lodge"
          />
        </Fade>
      </div>
      <br />

      <br />
      <h3>Day 5 - DEPARTURE</h3>
      <Paragraph>
        We will wake up very early in the morning for breakfast and packing
        lunch and water. After completion of breakfast, we will drive to Entebbe
        international airport. 9 hours driving from KASESE TO Entebbe airport.
      </Paragraph>
      <br />
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="rwandair" />
        </Fade>
      </div>
      <br />
      <button onClick={() => openBooking()}>BOOK NOW</button>
    </div>
  );
}
