import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./slides.scss";

// import required modules
import { Navigation,Autoplay } from "swiper";
import { NavLink } from "react-router-dom";

function Slides(props) {
  const data = props?.data;
    console.log(data)

    const [ setSwiperRef] = useState(null);


      const trancateString = (str,num) =>{
        if(str?.length > num ){
            return str.slice(0, num) +'...';
        }else{
            return str;
        }
    }





  return (
    <div className="pb-6">

    <>
      <Swiper
        onSwiper={setSwiperRef}
        // slidesPerView={3}
        centeredSlides={true}

        breakpoints={{
            // when window width is >= 640px
            480: {
              width: 480,
              slidesPerView: 1,
              spaceBetween: 15,
            },
            640: {
              width: 640,
              slidesPerView: 2,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 2,
            },
            1080: {
              width: 1080,
              slidesPerView: 3,
              spaceBetween: 30

            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}

        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper"
      >

            {data?.map(item =>
            <SwiperSlide key={item?.title} className=' flex flex-col'>
            <img src={item?.image} alt="" className="" />
            <div className="px-4 py-2">
                <h1 className="text-primary text-base font-medium my-4 bg-tertiary px-2">{item?.title}</h1>
                <p className="py-2 text-sm">{trancateString(item?.description, 100)}</p>
                <hr className="my-3 text-primary"/>
                <NavLink smooth={true} to={item?.link} className=""><button className="w-full bg-primary text-white text-center rounded-sm py-2">Explore</button></NavLink>
            </div>
            </SwiperSlide>
            )}
        
      </Swiper>

    </>


    </div>
  )
}

export default Slides