import React from "react";
import "./Card.css";
import Fade from "react-reveal/Fade";
import {LazyLoadImage} from 'react-lazy-load-image-component'

const Card = ({ image, title, description, link, id }) => {
  return (
    <Fade bottom>
      <div
        className="card"
        style={{ flexDirection: `${id % 2 === 0 ? "row" : "row-reverse"}` }}
      >
        <Fade>
          <div className="image">
            <LazyLoadImage effect="blur" src={image} alt={title} />
          </div>
        </Fade>
        <Fade bottom>
          <div className="desc">
            <div className="words">
              <h2>{title}</h2>
              {description}
            </div>
            <div className="button">
              <button>
                <a href={link}>Read More</a>
              </button>
            </div>
          </div>
        </Fade>
      </div>
    </Fade>
  );
};

export default Card;
