import { Component } from "react";
import background from "../../Backgrounds/greek-vase.png";
import gorilla from "../../Images/savinggorillas-14-06-2024-0011.jpg";
import gameDrive from "./images/gamedrive.jpg";
import gorillaTracking from "./images/gorilla_tracking.jpg";
import lion from "./images/lion.jpg";
import gorillaS1 from "./images/savinggorillas-14-06-2024-0003.jpg";

export default class SuggestedItineraries extends Component {
  render() {
    const itinerariesArray = [
      {
        title: "3 days in Rwanda",
        link: "/3daystour",
        image: gorilla,
      },
      {
        title: "5 Days in Rwanda",
        link: "/5days-in-rwanda",
        image: gorillaS1,
      },
      {
        title: "5 Days in Uganda",
        link: "/5days-in-uganda",
        image: gorillaTracking,
      },
      {
        title: "9 Days in Rwanda & Uganda",
        link: "/9daystour",
        image: lion,
      },
      {
        title: "14 days in Rwanda",
        link: "/14daystour",
        image: gameDrive,
      },
    ];

    return (
      <div className="w-full my-10">
        <div className="mt-24 text-center mb-4 text-2xl font-semibold phone:text-xl">
          <h1 className="text-black">Explore our itineraries</h1>
        </div>
        <div className="w-[95%] mx-auto grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 desktop:grid-cols-4 gap-6 py-3">
          {itinerariesArray &&
            itinerariesArray?.map((itenerary) => (
              <div
                style={{
                  backgroundImage: `url(${background})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "repeat",
                }}
                className="w-full border border-[#ccc] h-60 rounded-[8px] p-3 flex flex-col justify-around shadow-sm"
                key={itenerary._id}
              >
                <div className="relative w-full text-white text-center rounded overflow-hidden">
                  <img
                    src={itenerary.image || gorilla}
                    alt="Group cover"
                    className="w-full object-cover"
                  />
                  <div className="absolute w-full h-full top-1/2">
                    <a
                      className="drop-shadow-xl text-white border w-full px-4 py-2 bg-primary hover:shadow-4xl hover:text-primary hover:border-none hover:bg-white ease-in-out duration-300 rounded-tl-xl rounded-br-xl"
                      href={itenerary.link}
                    >
                      {itenerary.title} &rarr;
                    </a>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}
