import React from "react";
import Card from "./Card";
import "./Cards.css";

const Cards = ({ list }) => {
  const rendersCards = list.map((card, index) => {
    return (
      <Card
        key={index}
        image={card.image}
        title={card.title}
        description={card.description}
        id={index}
        link={card.link}
      />
    );
  });

  return <div className="cards">{rendersCards}</div>;
};

export default Cards;
