import axios from "axios";
import React, { Component } from "react";
import "./Booking.css";

export default class Booking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookings: [],
      password: "Olivier",
      check: "",
    };
  }
  getBookings = async () => {
    let bookings = await axios.get(
      process.env.REACT_APP_BACKEND_URL +`messages`
    );
    console.log("Bookings",bookings?.data?.data);
    
    this.setState({ bookings: bookings?.data?.data });
  };
  componentDidMount() {
    this.getBookings();
  }
  remove = async (id) => {
    console.log(id);
    
    await axios
      .delete(process.env.REACT_APP_BACKEND_URL+`messages/` + id)
      .then(() => this.getBookings())
      .catch((err) => console.log(err));
  };
  render() {
    if (this.state.password == this.state.check) {
      return (
        <div style={{ marginTop: "100px" }} className="bookingsPage">
          <table class="bookings">
            <caption>{this.state.bookings.length} people booked</caption>
            <tr>
              <th>No</th>
              <th>Prefix</th>
              <th>Names</th>
              <th>Tour</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Country</th>
              <th>City</th>
              <th>Date &amp; Month</th>
              <th>N. of people</th>
              <th>Budget</th>
              <th>Accomodation</th>
              <th>Preffered Activities</th>
              <th>Requested at</th>
              <th>Action</th>
            </tr>
            {this.state.bookings.map((booking) => {
              
              return (
                <tr>
                  <td>{this.state.bookings.indexOf(booking) + 1}</td>
                  <td>{booking.prefix}</td>
                  <td>{booking.names}</td>
                  <td>{booking.tour}</td>
                  <td>{booking.email}</td>
                  <td>{booking.phone}</td>
                  <td>{booking.country}</td>
                  <td>{booking.city}</td>
                  <td>{booking.date + " " + booking.month}</td>
                  <td>{booking.nOfPeople}</td>
                  <td>{booking.budget}</td>
                  <td>{booking.accomodation}</td>
                  <td>
                    {booking.prefActivities &&
                      booking.prefActivities.map((prefActivity, i) => (
                        <span key={i}>{prefActivity}</span>
                      ))}
                  </td>
                  <td>{new Date(booking.createdAt).toDateString()}</td>
                  <td>
                    <button onClick={() => this.remove(booking._id)}>
                      Remove
                    </button>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      );
    } else {
      return (
        <div className="passCheck">
          <h3>Enter password to view this page</h3>
          <span ref={(ref) => (this.nomatch = ref)}></span>
          <input
            type="password"
            placeholder="Password"
            onChange={(e) => this.setState({ check: e.target.value })}
          />
          <button
            onClick={() => {
              this.nomatch.innerText = "Wrong password!";
              this.nomatch.style.backgroundColor = "rgba(255, 0, 0, 0.092)";
            }}
          >
            Continue
          </button>
        </div>
      );
    }
  }
}
