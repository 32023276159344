import React, { Component } from "react";
import Axios from "axios";
import "./Comments.css";

export default class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      names: "",
      comment: "",
      comments: [],
    };
  }
  getComments = async () => {
    let comments = await Axios.get(
      "https://gorilla-remarkable-backend.onrender.com/comments"
    );
    this.setState({
      comments: comments.data,
    });
  };
  componentDidMount() {
    this.getComments();
  }
  imageHandler = (e) => {
    e.preventDefault();
    const fileReadeer = new FileReader();
    fileReadeer.addEventListener("load", () => {
      if (fileReadeer.result.substring(5, 10) == "image") {
        this.setState({
          image: fileReadeer.result,
        });
        this.myimage.style.display = "block";
        this.label.style.display = "none";
      } else {
        alert("Please select a valid image");
      }
    });
    fileReadeer.readAsDataURL(e.target.files[0]);
  };
  namesHandler = (e) => {
    this.setState({
      names: e.target.value,
    });
  };
  commentHanlder = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };
  submitHandler = async (e) => {
    this.submitbtn.disabled = true;
    e.preventDefault();
    await Axios.post(
      "https://gorilla-remarkable-backend.onrender.com/comment",
      this.state
    )
      .then((res) => {
        alert("Thanks for your review, highly appreciated!");
        this.submitbtn.disabled = false;
        this.getComments();
      })
      .catch((err) => console.log(err.message));
  };
  render() {
    return true ? (
      <div></div>
    ) : (
      <div className="comments">
        <h3>
          Comments from our visitors <br />
          <span>{this.state.comments.length} comments</span>
        </h3>
        <section>
          {this.state.comments.map((comment) => (
            <p>
              <div className="singleComment">
                <div
                  style={{
                    minWidth: "50px",
                    height: "50px",
                    overflow: "hidden",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                >
                  <img src={comment.image_url} alt="" />
                </div>
                <div>
                  <strong>{comment.names}</strong>
                  <span>{new Date(comment.createdAt).toDateString()}</span>
                  <br />
                  <span>{comment.comment}</span>
                </div>
              </div>
            </p>
          ))}
        </section>
        <form onSubmit={this.submitHandler}>
          <h5>Express your view in a comment!</h5>
          <div>
            <div>
              <textarea
                name="commnet"
                id="comment"
                cols="70"
                rows="3"
                required
                placeholder="Your comment..."
                onChange={this.commentHanlder}
              ></textarea>
              <label htmlFor="image">
                <span ref={(ref) => (this.label = ref)}>Image</span>
                <img
                  src={this.state.image}
                  alt=""
                  style={{ display: "none", height: "100px" }}
                  ref={(ref) => (this.myimage = ref)}
                />
              </label>
              <input
                type="file"
                name="image"
                id="image"
                required
                style={{ visibility: "hidden", width: 0 }}
                onChange={this.imageHandler}
              />
            </div>
            <div>
              <input
                type="text"
                id="names"
                name="names"
                required
                placeholder="Your names"
                onChange={this.namesHandler}
              />
              <input
                type="submit"
                value="Post"
                ref={(ref) => (this.submitbtn = ref)}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
