import React from "react";
import { AiFillSafetyCertificate } from "react-icons/ai";
import "./RwandaInformation.scss";

export default function RwandaInformation() {
  return (
    <div className="rwandaInfo content">
      <h3>Rwanda Information</h3>
      <hr />
      <section className="text-gray antialiased text-base font-light leading-7">
        <h4 className="text-xl">Visa</h4>
        <p>
          You don't need to come up with Visa due to tourists visit Rwanda are
          allowed to visit Rwanda without a Visa for a period of up to 90 days,
          additionally Tourists get Visas upon arrival.
        </p>
        <h4 className="text-xl">What do you need to pack?</h4>
        <p>
          For Gorilla Tracking don't forget to bring a raincoat or windbreaker
          Due to Volcanoes park in rain forest, it usually likes to rain,
          remember to bring hiking boots, a hat, and garden gloves so that avoid
          stinging nettles, gators are necessary also, A light sweater for
          evening is necessary.
        </p>
        <p>
          If you like to swim, remember to bring your swimming costume, most of
          the hotels in Rwanda have swimming pools if you included swimming in
          your itinerary it is an opportunity to make it, particularly Rwanda
          has lake Kivu where you can swim in as well as Rubavu and Kibuye.
        </p>
        <h4 className="text-xl">Drinking water</h4>
        <p>
          It is not advisable to come up with water because we help you to get
          water everywhere you go.
        </p>
        <h4 className="text-xl">Payment methods</h4>
        <p>
          Credit cards and Visa cards are accepted for payment and it is
          advisable to use them in the context of avoiding stealing can occur.
        </p>
        <h4 className="text-xl">Security</h4>
        <p>
          Rwanda is known as a safe destination to travel in here in Africa.
        </p>
        <h4 className="text-xl">Camera and film</h4>
        <p>
          Don't forget your camera and come up with loads of film if you are
          still using analog, remember also to bring batteries of all your
          equipment, there is no charge for private filming in the parks,
          however, there is free for commercial filming and prior arrangement
          have to be made.
        </p>

        <div className="my-4 pt-6">
          <h1 className="mb-2 text-xl font-bold text-primary">
            Yello fever and malaria precautions.
          </h1>
          <p>
            For Yello fever vaccine. Rwanda doesn't request yellow fever
            certificates to the Tourists who come to Rwanda.
          </p>
          <p className="my-2">
            As far as Malaria precautions, avoid mosquito bites by covering up
            with clothing such as long sleeves and long trousers especially
            after sunset, using insect repellents on exposed skin and when
            necessary, sleep under a mosquito net. Check with your doctor or
            nurse about suitable antimalarial tablets. Promoting domestic and
            international Tourism in Rwanda Country and providing excellent
            services and sharing Authentic experiences to our clients.
          </p>
        </div>

        <div className="my-4 pt-6">
          <h1 className="mb-2 text-xl font-bold text-primary">
            Rwanda climate.
          </h1>
          <p>
            Rwanda's Temperatures Generally, during the day, the temperature
            ranges between 22°C and 27°C while at night they drop to between
            16°C and 21°C.
          </p>

          <div className="flex items-center my-4">
            <AiFillSafetyCertificate className="mr-2 text-secondary" />
            <h3 className="text-lg font-bold text-primary">
              Dry Season–June to September
            </h3>
          </div>

          <p className="my-2">
            There is little rainfall during these months in most of the country,
            but it can still rain at any time in the rainforests.
          </p>

          <ul className="list-disc ml-8">
            <li className="my-1">
              <p>
                June, July & August – In the east of the country, afternoon
                temperatures are usually around 27°C. In the mountain parks it
                is a lot colder. Nyungwe has daytime temperatures of around
                21°C/70°F, and Volcanoes NP is even colder with temperatures
                around 16°C/61°F.
              </p>
            </li>
            <li className="my-1">
              <p>
                September – Early September is still dry, but sometime this
                month the rains may begin. Temperatures remain the same. The
                mountain parks will receive quite a bit of rain in September.
              </p>
            </li>
          </ul>

          <div className="flex items-center my-4">
            <AiFillSafetyCertificate className="mr-2 text-secondary" />
            <h3 className="text-lg font-bold text-primary">
              Wet Season–October to May
            </h3>
          </div>

          <p className="my-2">
            The Wet season is characterized by two periods of rain, the short
            rains and the long rains, interrupted by a drier spell.
          </p>

          <ul className="list-disc ml-8">
            <li className="my-1">
              <p>
                October, November & December – ‘Short rains’: Nyungwe and
                Volcanoes NP tend to be very wet throughout the rainy season.
                Daytime temperatures in Nyungwe are about 19°C and in Volcanoes
                NP it drops to 15°C. The rains probably won’t interfere with
                your safari in Akagera.
              </p>
            </li>
            <li className="my-1">
              <p>
                January & February – There tends to be a drier spell between the
                short and long rains. The exact timing is unpredictable. In
                Nyungwe and Volcanoes NP it will still be very wet and it can
                rain anytime.
              </p>
            </li>
            <li className="my-1">
              <p>
                March, April & May – ‘Long rains’: these are the wettest months.
                It tends to rain most days, although seldom for the whole day in
                the drier east part of the country. In Nyungwe and Volcanoes NP,
                it can rain for days{" "}
              </p>
            </li>
          </ul>
        </div>
        <h4 className="text-xl">Plastic bags</h4>
        <p>
          It's forbidden to bring plastic bags in Rwanda in terms of
          environmental conservation.
        </p>
        <h4>Gorilla permit price</h4>
        <p>
          Rwanda Development Board increased the Gorilla permit price from 750$
          to 1,500$, A new exclusive package for Tourists who want to book a
          whole family of Gorillas was introduced at 15,000$ with exclusive
          personalized tour guide services.
        </p>
        <h4 className="text-xl">Why does Gorilla permit price increase?</h4>
        <p>
          Gorilla permit price increased for the reason of improving mt Gorillas
          Conservationists efforts and supporting local communities those people
          live around the parks, 75% from Tourism revenue goes into conservation
          activities, while revenue sharing program was 5% increased to 10%,
          this is 10% from Tourism revenue goes into local communities to
          support their projects such as building the schools, health centers
          and roods for them. While 15% of Tourism revenue goes into Researching
          activities.{" "}
        </p>
        <h4 className="text-xl">How to get to Rwanda?</h4>
        <p>
          Kigali international airport connects the world to Rwanda with flights
          from everywhere you come from to Rwanda, and we help you with Air
          tickets services.
        </p>
        <h4 className="text-xl">Time to visit Rwanda</h4>
        <p>
          From June to December and from December to February, this is a great
          time to track our animals for taking beautiful photos due to it summer
          time.
        </p>
      </section>
    </div>
  );
}
