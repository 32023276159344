import { Suspense, lazy, useState } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-slideshow-image/dist/styles.css";
import "./App.css";
// Import Swiper styles
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Link } from "react-scroll";
import "swiper/css";
import Comments from "./Components/Comments/Comments";
import Booking from "./Pages/Bookings/Booking";
import RwandaInformation from "./Pages/RwandaInformation/RwandaInformation";
import Days3 from "./Pages/Tours/3_days_gorilla_tracking_in_rwanda";
import AboutUs from "./Pages/aboutUs/AboutUs";
import Birdwatching from "./Pages/activity/BirdWatching";
import BisoHiking from "./Pages/activity/BisoHiking";
import BoatTrip from "./Pages/activity/BoatTrip";
import CanopyWalkway from "./Pages/activity/CanopyWalkway";
import ChimpTrackingVolcanoes from "./Pages/activity/ChimpTracking";
import ChimpTrackingNyungwe from "./Pages/activity/ChimpTrackingNyungwe";
import DianFosseyTomb from "./Pages/activity/DianFosseyTomb";
import GameDriving from "./Pages/activity/GameDriving";
import GoldenMonkeyTracking from "./Pages/activity/GoldenMonkeyTracking";
import GorillaTracking from "./Pages/activity/GorillaTracking";
import KalisimbiHiking from "./Pages/activity/KalisimbiHiking";
import NationalParks from "./Pages/activity/NationalParks";
import TraditionalCulture from "./Pages/activity/TraditionalCulture";
import ExploreAkagera from "./Pages/explore/Explore_akagera";
import ExploreNyungwe from "./Pages/explore/Explore_nyungwe";
import ExploreVirunga from "./Pages/explore/Explore_virunga";
import Home from "./Pages/home/Home";
import Location from "./Pages/location/Location";
import SuggestedItineraries from "./Pages/SuggestedItineraries/itineraries";
import Days5Rwanda from "./Pages/Tours/5_days_gorilla_tracking_and_game_safari";
import Days5Uganda from "./Pages/Tours/5_days_in_uganda_for_game_safari_and_gorilla_track";
import Days9 from "./Pages/Tours/9_days_in_rwanda_and_uganda";
import Days14 from "./Pages/Tours/14_days_itinerary";

const Nav = lazy(() => import("./Components/nav/Nav"));
const Footer = lazy(() => import("./Components/footer/Footer"));

const App = () => {
  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);

  return false ? (
    <span></span>
  ) : (
    <Router>
      <Suspense fallback={<div> </div>}>
        <Nav />
      </Suspense>
      <Link
        to="top"
        smooth={true}
        style={{ display: showScroll ? "block" : "none" }}
      >
        <i className="caret-up">↑</i>
      </Link>
      <a
        id="chat_with_us"
        href="https://wa.me/250780780263?text=Hey,%20I%20really%20like%20your%20company."
      >
        <i className="fab fa-whatsapp"></i> Chat with us
      </a>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Home} exact path="/index" />
        <Route component={ExploreAkagera} exact path="/explore" />
        <Route component={GorillaTracking} exact path="/activity" />
        <Route component={ExploreVirunga} exact path="/explore/volcanoes" />
        <Route
          component={SuggestedItineraries}
          exact
          path="/explore/itineraries"
        />
        <Route component={ExploreAkagera} exact path="/explore/akagera" />
        <Route component={ExploreNyungwe} exact path="/explore/nyungwe" />
        <Route
          component={GorillaTracking}
          exact
          path="/explore/volcanoes/gorilla tracking"
        />
        <Route
          component={ChimpTrackingVolcanoes}
          exact
          path="/explore/volcanoes/chimpanzee tracking"
        />
        <Route
          component={GoldenMonkeyTracking}
          exact
          path="/explore/volcanoes/golden monkey tracking"
        />
        <Route
          component={KalisimbiHiking}
          exact
          path="/explore/volcanoes/kalisimbi hiking"
        />
        <Route
          component={BisoHiking}
          exact
          path="/explore/volcanoes/bisoke hiking"
        />
        <Route
          component={DianFosseyTomb}
          exact
          path="/explore/volcanoes/dian fossey tomb"
        />
        <Route
          component={ChimpTrackingNyungwe}
          exact
          path="/explore/nyungwe/chimpanzee tracking"
        />
        <Route
          component={Birdwatching}
          exact
          path="/explore/nyungwe/birdwatching"
        />
        <Route
          component={CanopyWalkway}
          exact
          path="/explore/nyungwe/canopy walkway"
        />
        <Route
          component={GameDriving}
          exact
          path="/explore/akagera/game driving"
        />
        <Route component={BoatTrip} exact path="/explore/akagera/boat trip" />
        <Route
          component={TraditionalCulture}
          exact
          path="/explore/traditional culture"
        />
        <Route component={NationalParks} exact path="/explore/national parks" />
        <Route component={AboutUs} exact path="/aboutus" />
        <Route component={Location} exact path="/location" />
        <Route component={Days3} exact path="/3daystour" />
        <Route component={Days5Uganda} exact path="/5days-in-uganda" />
        <Route component={Days5Rwanda} exact path="/5days-in-rwanda" />
        <Route component={Days9} exact path="/9daystour" />
        <Route component={Days14} exact path="/14daystour" />

        <Route component={Booking} exact path="/booking" />
        <Route
          component={RwandaInformation}
          exact
          path={"/rwanda-information"}
        />
      </Switch>
      <Suspense fallback={<div> </div>}>
        <Comments />
        <Footer />
      </Suspense>
    </Router>
  );
};

export default App;
