import React, { useEffect } from "react";
import "./activity.css";
import gameDrive1 from "./Images/gamedrive.jpg";
import gameDrive2 from "./Images/game_drive1.jpg";
import boat_trip1 from "./Images/boatTrip4.jpg";
import boat_trip2 from "./Images/boat_trip2.jpg";
import boat_trip3 from "./Images/boatTrip1.jpg";
import boat_trip4 from "./Images/boatTrip2.jpg";
import boat_trip5 from "./Images/boatTrip3.jpg";
import gorilla_tracking from "./Images/gorillaTracking.jpg"
import gorilla_tracking1 from "./Images/gorilla_tracking1.jpg";
import gorilla_tracking2 from "./Images/gorilla-tracking2.jpg";
import gorilla_tracking3 from "./Images/gorillaTracking3.jpg"
import chimpanzee_tracking2 from "./Images/chimpanzee-tracking-2.jpg";
import chimpanzee_track1 from "./Images/chimpanzee-trekking-1.jpg";
import chimpanzee_track2 from "./Images/chimpanzee-trekking-2.jpg";
import golden_monkey1 from "./Images/goldenMonkey_tracking1.jpg";
import golden_monkey2 from "./Images/goldenMonkey_tracking2.jpg";
import golden_monkey3 from "./Images/goldenMonkey.jpg"
import birds1 from "./Images/download.jpg";
import birds2 from "./Images/birding-safari-rwanda.jpg";
import kali_hike1 from "./Images/kali_hike.jpg";
import kali_hike2 from "./Images/kali_hike2.jpg";
import biso_hike1 from "./Images/bisi_hike1.jpg";
import biso_hike2 from "./Images/mount-bisoke-cover-image.jpg";
import canopy_walk1 from "./Images/canopy_walk1.jpg";
import canopy_walk2 from "./Images/canopy_walk2.jpg";
import museum1 from "./Images/unnamed (1).jpg";
import museum2 from "./Images/unnamed.jpg";
import culture1 from "./Images/culture1.jpg";
import culture2 from "./Images/culture2.jpg";
import diane from "./Images/diane.jpg";
import dian from "./Images/dian.jpg";
import elephant from "./Images/elephant.jpg";
import rhino from "./Images/rhino.jpg";
import leopard from "./Images/leopard.jpg";
import leopard2 from "./Images/leopard (1).jpg";
import hippo from "./Images/hippo.jpg";
import bisokeHiking from "./Images/bisokeHiking.jpg";
import bisokeHiking1 from "./Images/bisokeHiking2.jpg";
import bisokeHiking2 from "./Images/bisokeHiking3.jpg";
import bisoHike from "./Images/bisoHike.jpg"
import bisoHike2 from "./Images/bisoHike2.jpg"
import bisoHike3 from "./Images/bisoHike3.jpg"
import bisoHike4 from "./Images/bisoHike4.jpg"
import bisoHike5 from "./Images/bisoHike5.jpg"
import bisoHike6 from "./Images/bisoHike6.jpg"
import bisoHike7 from "./Images/bisoHike7.jpg"
import canopywalkway from "./Images/canopyWalkway.jpg";
import canopyWalkway1 from "./Images/canopy_walk3.jpg"
import canopyWalkway2 from "./Images/canopy_walk4.jpg"
import canopyWalkway3 from "./Images/canopy_walk5.jpg"
import culture from "./Images/culture.jpg"
import culture4 from "./Images/traditionalCulture.jpg"
import culture5 from "./Images/traditionalCulture1.jpg"
import culture6 from "./Images/traditionalCulture2.jpg"
import culture7 from "./Images/traditionalCulture3.jpg"
import culture8 from "./Images/culture3.jpg"
import culture9 from "./Images/culture4.jpg"
import culture10 from "./Images/culture5.jpg"
import culture11 from "./Images/culture6.jpg"
import culture12 from "./Images/culture (1).jpg"
import culture13 from "./Images/culture7.jpg"
import culture14 from "./Images/culture8.jpg"
import culture15 from "./Images/culture9.jpg"
import culture16 from "./Images/culture10.jpg"
import culture17 from "./Images/culture11.jpg"
import culture18 from "./Images/culture12.jpg"
import gorTracking from "./Images/gorTracking.jpg"
import gorTracking1 from "./Images/gorTracking1.jpg"
import gorTracking2 from "./Images/gorTracking2.jpg"
import gorTracking3 from "./Images/gorTracking3.jpg"
import gorTracking4 from "./Images/gorTracking4.jpg"
import gorTracking5 from "./Images/gorTracking5.jpg"
import gorTracking6 from "./Images/gorTracking6.jpg"
import gorTracking7 from "./Images/gorTracking7.jpg"
import gorTracking8 from "./Images/gorTracking8.jpg"
import gorTrackingVid from "./Images/gorTracking.mp4"
import gorTrackingVid1 from "./Images/gorTrackingVid1.mp4"
import gorTrackingVid2 from "./Images/gorTrackingVid2.mp4"
import gorTrackingVid3 from "./Images/gorTrackingVid3.mp4"
import goldenMonkey1 from "./Images/goldenMonkey1.jpg"
import goldenMonkey2 from "./Images/goldenMonkey2.jpg"
import goldenMonkey3 from "./Images/goldenMonkey3.jpg"
import goldenMonkey4 from "./Images/goldenMonkey4.jpg"
import goldenMonkey5 from "./Images/goldenMonkey5.jpg"
import goldenMonkey6 from "./Images/goldenMonkey6.jpg"
import goldenMonkey7 from "./Images/goldenMonkey7.jpg"
import goldenMonkey8 from "./Images/goldenMonkey8.jpg"
import natPark from "./Images/natParks.jpg"
import natParks1 from "./Images/natParks1.jpg"
import natParks2 from "./Images/natParks2.jpg"
import natParks3 from "./Images/natParks3.jpg"
import natParks4 from "./Images/natParks4.jpg"
import natParks5 from "./Images/natParks5.jpg"
import natParks6 from "./Images/natParks6.jpg"
import natParks7 from "./Images/natParks7.jpg"
import natParks8 from "./Images/natParks8.jpg"
import chimpTracking from "./Images/chimpTracking.jpg"
import chimpTracking1 from "./Images/chimpTracking1.jpg"
import chimpTracking2 from "./Images/chimpTracking2.jpg"
import chimpTracking3 from "./Images/chimpTracking3.jpg"
import chimpTracking4 from "./Images/chimpTracking4.jpg"
import chimpTracking5 from "./Images/chimpTracking5.jpg"
import chimpTracking6 from "./Images/chimpTracking6.jpg"
import chimpTracking7 from "./Images/chimpTracking7.jpg"
import chimpTracking8 from "./Images/chimpTracking8.jpg"
import chimpTracking9 from "./Images/chimpTracking9.jpg"
import chimpTracking10 from "./Images/chimpTracking10.jpg"
import chimpTracking11 from "./Images/chimpTracking11.jpg"
import chimpTracking12 from "./Images/chimpTracking12.jpg"
import chimpTracking13 from "./Images/chimpTracking13.jpg"
import chimpTracking14 from "./Images/chimpTracking14.jpg"
import chimpTracking15 from "./Images/chimpTracking15.jpg"
import chimpTracking16 from "./Images/chimpTracking16.jpg"
import chimpTracking17 from "./Images/chimpTracking17.jpg"
import chimpTracking18 from "./Images/chimpTracking18.jpg"
import chimpTracking19 from "./Images/chimpTracking19.jpg"
import chimpTracking20 from "./Images/chimpTracking20.jpg"
import birdwatNyungwe from "./Images/birdwatNyungwe.jpg"
import birdwatNyungwe1 from "./Images/birdwatNyungwe1.jpg"
import birdwatNyungwe2 from "./Images/birdwatNyungwe2.jpg"
import birdwatNyungwe3 from "./Images/birdwatNyungwe3.jpg"
import birdwatNyungwe4 from "./Images/birdwatNyungwe4.jpg"
import birdwatNyungwe5 from "./Images/birdwatNyungwe5.jpg"
import birdwatNyungwe6 from "./Images/birdwatNyungwe6.jpg"
import birdwatNyungwe7 from "./Images/birdwatNyungwe7.jpg"
import birdwatNyungwe8 from "./Images/birdwatNyungwe8.jpg"
import birdwatNyungwe9 from "./Images/birdwatNyungwe9.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Helmet } from "react-helmet";

const list = [
  {
    title: "Game Driving",
    content: {
      text: (
        <p>
          In Akagera National Park we offer an opportunity to see and photograph wildlife from 
          a safe vehicle. Let us do the driving and you relax capturing pictures of a lifetime.
          Be prepared to see Elephants, Rhinoceros, Leopards, Lions and Hippopotamus. We suggest 
          this after gorilla tracking in Volcanoes National Park - relaxing but absolutely fascinating.
        </p>
      ),
      images: [gameDrive1, elephant, rhino, gameDrive2, hippo, leopard2],
    },
  },
  {
    title: "Boat Trip",
    content: {
      text: (
        <p>
          We offer a boat trip on Lake Ihema in Akagera National Park. There are pre scheduled morning and 
          afternoon departures lasting between 1-2 hours. However we can offer a private tour at your 
          convenience. This is a grreat opportunity to be close up with Hippos, Monitor Lizards and Crocodiles!
        </p>
      ),
      images: [boat_trip1, boat_trip2, boat_trip3, boat_trip4, boat_trip5],
    },
  },
  {
    title: "Gorilla Tracking",
    content: {
      text: (
        <p>
          As their name implies, mountain gorillas live in forests high in the
         mountains, at elevations of 8,000 to 13,000 feet. They have thicker fur,
          and more of it, compared to other great apes. The fur helps them to survive
           in a habitat where temperatures often drop below freezing. But as 
           humans have moved more and more into the gorillas’ territory, the gorillas
          have been pushed farther up into the mountains for longer periods, forcing
           them to endure dangerous and sometimes deadly conditions. <br/><br/>
           This is the closest you will ever get to a highland gorilla. They are 
           only seen here in Rwanda at three parks : Volcano National Park, Mughinga 
           National; Park and in Vilunga Natioinal Park.You will come face to face 
           with beautiful families of Highland Gorillas and be enchanted with the 
           babies and their team leader named Silverback. 
        </p>
      ),
      videos: [gorTrackingVid1, gorTrackingVid2],
      images: [gorilla_tracking,gorilla_tracking1, gorilla_tracking3,gorilla_tracking2, gorTracking1, gorTracking2, gorTracking4, gorTracking7, gorTracking8],
    },
  },
  {
    title: "Chimpanzee Tracking",
    content: {
      text: (
        <p>
          Chimpanzee are our closest relatives as they have 98.7% DNA, making
          them exciting to be observed especially when they display what we call
          "Human behaviour" Chimpanzee communicate with ear deafening screams,
          loud hoots and hit tree barks or buttress roots. It is such a
          thrilling and hair rising moment that you can't afford to miss.
        </p>
      ),
      images: [chimpanzee_track1, chimpTracking2, chimpTracking3, chimpTracking4, chimpTracking7, chimpTracking10, chimpTracking14, chimpTracking15, chimpTracking16,chimpTracking17, chimpTracking18, chimpTracking19, chimpTracking20],
    },
  },
  {
    title: "Golden Monkey Tracking",
    content: {
      text: (
        <p>
          Golden monkeys are known to have unique characters and are a rare
          species that are endangered at the moment and they are currently found
          in the Volcanoes NP in Rwanda. They are easily recognized due to
          the yellow patches that can be seen all over their bodies.Golden
          monkey trekking in Rwanda mostly takes place in the mountains which are
          found in the Volcanoes NP. <br/><br/>
          There are two habituated groups of Golden Monkey, one group comprises 
          around 80 – 100 members and has its home at the foot of Mt Sabyinyo. 
          As with treks to see the Mountain Gorillas, treks to see the Golden 
          Monkeys take place in the mornings and visitors are permitted to stay 
          for 1 hour with the monkeys. Departure for the Golden Monkey trek is 
          from the Park’s Kinigi Headquarters at 07:00am
        </p>
      ),
      images: [golden_monkey1, golden_monkey3, golden_monkey2, goldenMonkey1, goldenMonkey4, goldenMonkey5, goldenMonkey6, goldenMonkey8],
    },
  },
  {
    title: "Bird Watching",
    content: {
      text: (
        <p>
          Birdwatching in Rwanda is very rewarding with a massive a bird list of
          over 750 species!, Birdwatching in Rwanda will reward you with a lot
          of bird species by covering a small area or spending a short time out
          on the birding trail.The Volcanoes National Park boasts of about 165
          bird species, 17 endemic to the area. It is must visit for birding to
          catch the rare species and also enjoy the breathtaking of Volcanoes National Park scenery         
          and a chance to encounter the Gorillas.
        </p>
      ),
      images: [birds1],
    },
  },
  {
    title: "Kalisimbi Hiking",
    content: {
      text: (
        <p>
          Visitors to the Volcanoes National Park in Rwanda usually combine
          Gorilla trekking with a Karisimbi mountain hike. Climbing mount
          Karisimbi can be a strenuous but special experience. Because of its
          location within the Volcanoes National Park, visitors should expect to
          come across several bird species, Hiking Mount Karisimbi Volcanosee
          golden monkeys, occasionally mountain gorillas and passing close to
          the grave of Dian Fossey – the famous researcher on Mountain gorillas.
          The trail and path towards the top of the volcano contains great
          physical features/landscape, volcanic features, dense forest and
          vegetation which are beautiful to behold.
        </p>
      ),
      images: [kali_hike2, kali_hike1],
    },
  },
  {
    title: "Bisoke Hiking",
    content: {
      text: (
        <p>
          The mountain Bisoke adventure is one of the most memorable activities
          in Rwanda. The top of this Volcano has a spectacular crater lake. It
          is simply a 6 – hours hike including the 2 hours descending down to
          the starting point. Experienced hikers can get to the top of this
          mountain in a matter of 3 hours or even less and return to the base in
          about an hour.
        </p>
      ),
      images: [bisokeHiking, biso_hike2,bisokeHiking2, bisokeHiking1, bisoHike, bisoHike2, bisoHike3, bisoHike4, bisoHike5, bisoHike6, bisoHike7],
    },
  },
  {
    title: "Chimpanzee Tracking",
    content: {
      text: (
        <p>
          Nyungwe is an ideal destination for lovers of primates and most
          visitors will go for at least one tracking experience, the most
          popular of which is chimpanzee trekking. Sightings may not be as
          reliable or as clear as gorillas, since the chimps often remain in
          dense forest, so the sightings vary quite significantly. However,
          their engaging playfulness and human-like characteristics make the
          long day worthwhile. The best chance of seeing them is when they're
          high up in the trees before they come down to forage for food, so make
          sure you bring your binoculars! <br/><br/>
          Chimpanzee trekking in Nyungwe forest starts off from three reception
           centers, that’s Uwinka, Kitabi and Gisakura where the tourists meet the 
           park rangers and the tourists are also expected to be at the reception 
           as early as 4: 30am and by 5 am, you drive to the starting point of the 
           trek into the forest.
        </p>
      ),
      images: [chimpanzee_track2, chimpanzee_tracking2, chimpTracking, chimpTracking1, chimpTracking9, chimpTracking5, chimpTracking6, chimpTracking8, chimpTracking11, chimpTracking12, chimpTracking13],
    },
  },
  {
    title: "Bird Watching",
    content: {
      text: (
          <p>
            Nyungwe National Park is a tropical mountain rainforestthat 
            is home to 322 bird species, 13 different unique primates 
            and numerous plant species endemic to this area. To date 
            scientist have discovered 29 new bird species but the star 
            attraction is undoubtedly the Red Collared Babbler. Rwanda 
            is the only safe place to see this stunning bird. As well 
            visitors are likely to see and hear hundreds of songbirds, 
            birds of prey like hawks owls and eagles, herons, storks 
            and cranes as well as Ostriches and Emus.
            <br/>
            <br/>
            <h4>The only thing required is patience and binoculars!</h4>
          </p>
      ),
      images: [birdwatNyungwe, birds1, birdwatNyungwe1, birdwatNyungwe2, birdwatNyungwe3, birdwatNyungwe4, birdwatNyungwe5, birdwatNyungwe6, birdwatNyungwe7, birdwatNyungwe9],
    },
  },
  {
    title: "Canopy Walkway",
    content: {
      text: (
        <p>
          A guided tour on a suspension bridge over 160 meters long 
          and 70 meters high above the canopy awaits hikers on the 
          Imbaraga and Umuyove trails. Since the last Ice Age  the 
          Nyungwe Forest has grown to be a hot bed of diversity. 
          Along this spectacular hour long walkway from the Uwinka 
          Reception Centre, visitors will see countless butterflies, 
          wild orchids and beautiful colourful birds - many of which 
          are native only to this region.
        </p>
      ),
      images: [canopywalkway,canopy_walk1, canopy_walk2, canopyWalkway1, canopyWalkway2, canopyWalkway3],
    },
  },
  {
    title: "Ethnographic Museum",
    content: {
      text: (
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laborum
          explicabo rem dolor reiciendis magni expedita sint quas modi! Modi
          maiores, impedit pariatur consequuntur tempora ducimus culpa nesciunt
          nulla in sunt?
        </p>
      ),
      images: [museum1, museum2],
    },
  },
  {
    title: "Traditional Culture",
    content: {
      text: (
        <p>
          Rwanda is rich in historical cultural traditions. On a culture tour we can visit and 
          share many experiences with Rwandans in their homes and communities. Watch and participate 
          in traditional  dances, see a wedding ceremony and maybe best of all taste and cook our 
          traditional food. Wash it down with banana beer!
        </p>
      ),
      images: [culture1, culture,  culture2, culture5, culture8, culture9,culture12, culture10, culture11, culture13, culture16, culture17],
    },
  },
  {
    title: "Dian Fossey's Grave Visiting",
    content: {
      text: (
        <p>
          Dian Fossey (January 16, 1932 – December 26, 1985) was an American
          primatologist and conservationist known for undertaking an extensive
          study of mountain gorilla groups from 1966 until her 1985 murder. She
          studied them daily in the mountain forests of Rwanda, initially
          encouraged to work there by paleoanthropologist Louis Leakey. She
          spent 20 years in Rwanda, where she actively supported conservation
          efforts, strongly opposed poaching and tourism in wildlife habitats,
          and made more people acknowledge sapient gorillas.
        </p>
      ),
      images: [diane, dian],
    },
  },
  {
    title: "National Parks",
    content: {
      text: (
        <p>
          <h4>Nyungwe national park</h4><br/>
          <span>
            One of the oldest rainforests in Africa, Nyungwe, is rich in biodiversity and is spectacularly 
            beautiful. The mountainous region is teaming with wildlife. There is a small population of 
            chimpanzees as well as 12 other species of primates, including the L’Hoest’s monkey endemic 
            to the Albertine Rift.<br/><br/>
            The distance from Kigali international airport to Nyungwe National Park is about two hours with a car.
          </span>
          <br/><br/><br/>
          <h4>Akagera national park</h4><br/>
          <span>
          Akagera National Park lies in eastern Rwanda, hugging the border with Tanzania. It's characterized by woodland,
           swamps, low mountains and savannah. The varied terrain shelters wildlife including zebras, giraffes, 
           elephants, lions and hundreds of bird species, such as the rare shoebill stork. In the southern part of 
           the park, vast Lake Ihema is home to hippos and crocodiles.
             <br/><br/>
             The distance from Kigali international airport to akagera national park is about 3 hours.
          </span>
          <br/><br/><br/>
          <h4>Volcanoes national park</h4><br/>
          <span>
            “In the heart of Central Africa, so high up that you shiver more than you sweat,” wrote the 
            eminent primatologist Diane Fossey, “are great, old volcanoes towering almost 15,000 
            feet, and nearly covered with rich, green rainforest – the Virungas.” <br/><br/> 
            Situated in the far northwest of Rwanda, Volcanoes National Park protects the steep 
            slopes of this magnificent mountain range – home of the endangered mountain gorilla 
            and a rich mosaic of montane ecosystems, which embrace evergreen and bamboo forest, 
            open grassland, swamp and heath. Volcanoes National Park is named after the chain 
            of dormant volcanoes making up the Virunga Massif: Karisimbi – the highest at 4,507m, 
            Bisoke with its verdant crater lakes, Sabinyo, Gahinga and Muhabura.
            <br/><br/>
            Distance from Kigali international airport to volcano national park is about 2 hours and 30 minutes
          </span>
        </p>
      ),
      images: [natPark, natParks1, natParks2, natParks3, gameDrive2, natParks8, natParks4, natParks5, natParks6, natParks7],
    },
  },
];

export default function DianFosseyTomb() {
  return (
    <div className="content activity top">
      <Helmet>
        <title>Dian Fossey Grave</title>
      </Helmet>
      <div className="activity_content">
        <h2>{list[13].title}</h2>
        <p>{list[13].content.text}</p>
      </div>
      <div className="activity_images">
        {list[13].content.videos && list[13].content.videos.map(video => 
          (
            <video src={video} controls autoPlay loop muted></video>
        ))} 
        {list[13].content.images.map((image, index) => (
          <div className="activity_image">
            <LazyLoadImage effect="blur"
              src={image}
              alt={list[13].title}
              className={"image"+index}
              height="100%"
              width="100%"
            />
          </div>       
        ))}     
      </div>
    </div>
  );
}
