import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Fade from "react-reveal";
import Book from "../../../Components/Book/Book";
import Rwandair from "../departure Images/rwandair.jpg";
import "../TourPage.css";
import Amarembo_gorilla_lodge_12 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (12).jpg";
import Amarembo_gorilla_lodge_11 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (11).jpg";
import Amarembo_gorilla_lodge_10 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (10).jpg";
import Amarembo_gorilla_lodge_9 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (9).jpg";
import Amarembo_gorilla_lodge_8 from "../hotels_lodges_partners/amarembo-gorilla-lodge/amarembo (7).jpg";

import Akagera_transit_lodge_9 from "../hotels_lodges_partners/akagera_transit_lodge/akagera_transit (9).jpg";
import Akagera_transit_lodge_8 from "../hotels_lodges_partners/akagera_transit_lodge/akagera_transit (8).jpg";
import Akagera_transit_lodge_7 from "../hotels_lodges_partners/akagera_transit_lodge/akagera_transit (7).jpg";

import Mantis_kivu_hotel_11 from "../hotels_lodges_partners/mantis_marina_bay_hotel/mantis (11).jpg";
import Mantis_kivu_hotel_10 from "../hotels_lodges_partners/mantis_marina_bay_hotel/mantis (10).jpg";
import Mantis_kivu_hotel_9 from "../hotels_lodges_partners/mantis_marina_bay_hotel/mantis (9).jpg";

import Canopy_walkaway_ from "./images/canopy_walk3.jpg";
import Chimpanze_trekking_1 from "./images/chimpanzee-trekking-2.jpg";
import Kimironko_city_tour from "./images/kimiroko.jpg";

import Heaven_boutique_hotel_11 from "../hotels_lodges_partners/heaven_boutique_and _restaurant_hotel/heaven_boutique (11).jpg";
import Heaven_boutique_hotel_10 from "../hotels_lodges_partners/heaven_boutique_and _restaurant_hotel/heaven_boutique (10).jpg";
import Akagera_2 from "./images/1108011826.jpg";
import Akagera_3 from "./images/-897437339.jpg";

import Diane_fossey_1 from "./images/diane5.jpg";

import Bisoke_hiking from "./images/bisoHike6.jpg";

import Gorilla_tracking_1 from "./images/tour16.jpg";
import Golden_monkey_tracking_1 from "./images/tour23.jpg";
import Paragraph from "../../../Components/Paragraph/Paragraph";

import Transfer_car_1 from "../safari cars/tourcars.jpg";
import Transfer_car_2 from "../safari cars/akagera game driving.jpg";

export default function Days14() {
  const [book, setBook] = useState(false);
  function openBooking() {
    setBook(true);
  }
  return (
    <div className="mainTourPage top">
      <Book
        handleClose={() => setBook(false)}
        book={book}
        selected="3 days tour"
      />
      <h1>14 DAYS ITINERARY</h1>
      <hr />
      <button onClick={() => openBooking()}>BOOK NOW</button>
      <h3>Day 1 - ARRIVAL</h3>
      <Paragraph>
        After arriving at Kigali internal airport, you will meet our driver
        guide and transfer to the hotel in KIGALI for rest waiting for an
        adventure on the following day.
        <br />
        <Paragraph>
          Tourist hotel:{" "}
          <strong className="uppercase">
            Heaven Boutique and restaurant hotel{" "}
          </strong>
        </Paragraph>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="Rwandair" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Heaven_boutique_hotel_10}
            alt="amarembo gorilla logde"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 2 - KIGALI CITY TOUR</h3>
      <Paragraph className="">
        We will wake up in the morning and have breakfast. Afterward we will
        start Kigali city tour at 9 a.m.
      </Paragraph>
      <Paragraph>
        Kigali city tour takes you through an amazing discovery of Rwanda
        capital. Kigali is the commercial center of Rwanda with most important
        political offices in the country. Kigali is also one of cleanest and
        safest cities in Africa. Walk through city center will lead you to
        impressive 5-star hotels and high-end restaurant, and bars. We will also
        visit kimironko market where the crafted items sold. Afterward we will
        drive to Kigali GENOCIDE memorial to witness dark history and tragedy
        Rwanda underwent and how the country renewed through reconciliation.
        After Kigali city tour, we will have our lunch at one of the best
        restaurants in Kigali and then afterward we will drive back to the hotel
        for rest.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist hotel:{" "}
        <strong className="uppercase">
          Heaven Boutique and restaurant hotel
        </strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-4 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Kimironko_city_tour}
            alt="gorilla"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Heaven_boutique_hotel_11}
            alt="amarembo gorilla logde"
          />
        </Fade>
      </div>
      <br />
      <br />
      <h3>Day 3 - TRANSFER TO NYUNGWE</h3>
      <Paragraph>
        We will wake up in the morning for breakfast, pack lunch and transfer to
        MANTIS Kivu Marina by hotel in RUSIZI. We will go with packed lunch as
        its long drive from KIGALI to RUSIZI, 5 Hours driving. Once we reach to
        the hotel, we will enjoy hotel facilities as swimming pool and other
        facilities as we will be waiting for chimps trekking on the following
        day.
        <br />
      </Paragraph>
      <br />
      <Paragraph>
        Tourist hotel:{" "}
        <strong className="uppercase">Mantis kivu marina hotel</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Transfer_car_1}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Mantis_kivu_hotel_9}
            alt="rwandair"
          />
        </Fade>
      </div>
      <br />

      {/* Start 4 days tour*/}

      <br />
      <h3>Day 4 - CHIMPANZEE TREKKING IN NYUNGWE NATIONAL PARK</h3>
      <Paragraph>
        We will wake up very early in the morning at 4:00 a.m. with packed
        breakfast, water and lunch and the we drive to NYUNGWE national park to
        meet the guide for briefings. Chimpanzee trekking starts from 5 a.m. and
        ends at 2 p.m. visitors get divided into the groups as one group is not
        more than 8 people. After briefings we begin chimps trekking through
        park vegetations. chimps trekking takes between 2 hours and 6 hours
        depends on where they are as they move from one place to another. Once
        you find them you spend one magical hour with them taking images as they
        are playing and having their morning breakfast, mothers nursing kids.
        Afterwords we come out from the park and driving back to the hotel for
        rest.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist hotel:{" "}
        <strong className="uppercase">Mantis kivu marina hotel</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Chimpanze_trekking_1}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Mantis_kivu_hotel_10}
            alt="rwandair"
          />
        </Fade>
      </div>
      <br />

      {/* End 4 days tour*/}

      {/* Start 5 days tour*/}

      <br />
      <h3>Day 5 - CANOPY WALKYWAY AND TEA PLANTATION TOUR</h3>
      <Paragraph>
        We will wake up in the morning for breakfast as usual and packing lunch
        and water and then we drive to UWINKA visitors center to meet the guide
        for briefings, after briefing we start trekking to canopy, its about 2
        hours from Uwinka visitors center and come back. Canopy walkway starts
        from 10 a.m. and it starts again during afternoon at 3 a.m. Conopy
        walkway measures is 160m in the length and 70m of height. After
        completion of tour, we will also execute tea plantation tour in the
        community, we will experience about tea harvesting together with locals.
        Afterward we drive back to the hotel for rest.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist hotel:{" "}
        <strong className="uppercase">Mantis kivu marina hotel</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Canopy_walkaway_}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Mantis_kivu_hotel_11}
            alt="rwandair"
          />
        </Fade>
      </div>
      <br />

      {/* End 5 days tour*/}

      {/* Start 6 days tour*/}

      <br />
      <h3>Day 6 - TRANSFER TO VOLCANOES NATIONAL PARK</h3>
      <Paragraph>
        We will wake up in the morning as usual and have our breakfast and
        packing our lunch and water. Afterwards we will drive to MUSANZE in
        KINIGI. 7 hours driving from RUSIZI to MUSANZE.
      </Paragraph>
      <Paragraph>
        Once we arrive at the lodge, we will have a rest waiting for another
        adventure on the following day.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Transfer_car_1}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_8}
            alt="amarembo lodge"
          />
        </Fade>
      </div>
      <br />

      {/* End 6 days tour*/}

      {/* Start 7 days tour*/}

      <br />
      <h3>Day 7 - HIKING BISOKE MOUNTAIN IN VOLCANOES NATIONAL PARK</h3>
      <Paragraph>
        We will have our early morning breakfast and packing our lunch and
        water. Afterwards we will drive to volcanoes park reception at 7 a.m.
        for briefing from guide. After briefing we start hiking around 8:30 a.m.
        BISOKE mountain is a home to mount gorillas with 3,711 m of elevation.
        Round trip takes about 6 hours. We will spend 6 hours experiencing
        biodiversity as we will be executing hiking. After completion of hiking,
        we will drive back to lodge for rest.
      </Paragraph>
      <br />
      <Paragraph>
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Bisoke_hiking}
            alt="bisoke hiking"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_9}
            alt="amarembo lodge"
          />
        </Fade>
      </div>
      <br />

      {/* End 7 days tour*/}

      {/* Start 8 days tour*/}

      <br />
      <h3>Day 8 - GOLDEN MONKEY TRACKING IN VOLCANOES NATIONAL PARK</h3>
      <Paragraph>
        We will again wake up in the morning for breakfast and then we drive
        back to volcanoes park reception at 7 a.m. for briefings from guide.
        Golden monkey tacking takes between 2 hours and 4 hours including one
        hour you spend with monkeys taking images, enjoying their play and
        movement as they run often on the top of the trees. Afterward we drive
        back to the lodge for lunch.
      </Paragraph>
      <Paragraph>
        During afternoon around at 4 p.m. we will drive to twins’ lakes BURERA
        and RUHONDO for boat ride, as we will be executing boat ride, we will be
        also experiencing bird species found on the shores and beautiful
        islands. Afterward we will drive back to the lodge for rest waiting for
        another adventure on the following day.
      </Paragraph>

      <br />
      <Paragraph>
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Golden_monkey_tracking_1}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_10}
            alt="amarembo lodge"
          />
        </Fade>
      </div>
      <br />

      {/* End 8 days tour*/}

      {/* Start 9 days tour*/}

      <br />
      <h3>Day 9 - DIAN FOSSEY TOMB IN VOLCANOES NATIONAL PARK</h3>
      <Paragraph>
        Again, we will have our morning breakfast and packing our lunch ad water
        and then we drive back to volcanoes park reception for briefings from
        guide. We will start trekking to Dian Fossey around 8:30 a.m. and entire
        round trip takes about 4 hours learning the journey and history of Dian
        Fossey and her commitment to the mountain gorillas. After completion of
        Dian Fossey tomb trekking, we will drive back to the lodge for rest
        waiting for magical adventure on the following day
      </Paragraph>

      <br />
      <Paragraph>
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Diane_fossey_1}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_11}
            alt="rwandair"
          />
        </Fade>
      </div>
      <br />

      {/* End 9 days tour*/}
      {/* Start 10 days tour*/}

      <br />
      <h3>Day 10 - GORILLA TRACKING IN VOLCANOES NATIONAL PARK</h3>
      <Paragraph>
        We will wake up in the morning as usual for breakfast at 6 a.m. after
        completion of breakfast we will drive back to volcanoes park reception
        for briefings. Visitors get divided into the groups as one group is not
        more than 8 people. After briefing we start gorilla tracking at 8:30
        a.m. through park dense vegetations in search of gorillas. We will meet
        also a group of experienced trackers to locate their whereabout. Gorilla
        trekking takes between 2 hours and 7 hours including one magical hour
        you spend with gorillas taking images of them as they are playing,
        mothers nursing their kids. Juveniles and silverbacks playing together.
        After spending this one magical hour we will drive back to the lodge for
        lunch.
      </Paragraph>
      <Paragraph>
        During afternoon at 3:30 p.m. we will drive to DIAN Fossey Gorilla Fund
        to meet the scientists. These gorilla scientists will share with us
        about gorilla lives deeply and their significance role for protecting
        mountain gorillas. Afterward we will drive back to the lodge for rest.
      </Paragraph>

      <br />
      <Paragraph>
        Tourist lodge: <strong>AMAREMBO GORILLA LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Gorilla_tracking_1}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Amarembo_gorilla_lodge_12}
            alt="rwandair"
          />
        </Fade>
      </div>
      <br />

      {/* End 10 days tour*/}
      {/* Start 11 days tour*/}

      <br />
      <h3>Day 11 - TRANSFER TO AKAGERA NATIONAL PARK</h3>
      <Paragraph>
        We will wake up in the morning for breakfast and packing lunch and
        water. Afterward we will drive to AKAGERA National Park. 6 hours driving
        from MUSANZE to KAYONZA. After arriving at the lodge, we will have rest
        waiting for game safari on the following day.
      </Paragraph>

      <br />
      <Paragraph>
        Tourist lodge: <strong>AKAGERA TRANSIT LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Transfer_car_2}
            alt="golden monkey"
          />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Akagera_transit_lodge_7}
            alt="rwandair"
          />
        </Fade>
      </div>
      <br />

      {/* End 11 days tour*/}

      {/* Start 12 days tour*/}
      <br />
      <h3>Day 12 - GAME SAFARI IN AKAGERA NATIONAL PARK</h3>
      <Paragraph>
        We will wake up very early in the morning at 5 a.m. for breakfast and
        driving to AKAGERA park reception for briefings from Akagera guide. Park
        opens from 6 a.m. and closes 6 p.m. we will experience wildlife
        profoundly. In particular we will experience BIG FIVE like lion,
        elephant, leopard, buffalo and rhinos. A part from big five we will also
        experience other wildlife like giraffes, zebras, hippos and many more.
        We will enjoy the beauty of the lakes found in Akagera like lake IHEMA
        and RWANYAKIZINGA. Around noon we will drive to the Picnic area for
        lunch and drinks. After completion of lunch, we will keep on our safari
        until sunset. After taking the images of sunset we will drive back to
        the lodge for rest.
      </Paragraph>

      <br />
      <Paragraph>
        Tourist lodge: <strong>AKAGERA TRANSIT LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Akagera_2} alt="golden monkey" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Akagera_transit_lodge_8}
            alt="rwandair"
          />
        </Fade>
      </div>
      <br />

      {/* End 12 days tour*/}

      {/* Start 13 days tour*/}
      <br />
      <h3>Day 13 - GAME SAFARI IN AKAGERA NATIONAL PARK</h3>
      <Paragraph>
        Again, we will wake up very early in the morning for breakfast and
        driving back to akagera national park for game safari. Executing one
        more day in Akagera national park is really important to the visitors in
        order to be able to meet all wildlife and feeling connections about
        wildlife as they meet them frequently. Around noon we drive back to
        MIHINDI which is picnic area in AKAGERA national park. After completion
        of our lunch, we will curry on our safari until unset again. Afterward
        we will drive back to the lodge for rest waiting for departure on the
        following day.
      </Paragraph>

      <br />
      <Paragraph>
        Tourist lodge: <strong>AKAGERA TRANSIT LODGE</strong>
      </Paragraph>
      <br />
      <div className="grid grid-cols-3 gap-2 phone:grid-cols-1">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Akagera_3} alt="golden monkey" />
        </Fade>
        <Fade bottom>
          <LazyLoadImage
            effect="blur"
            src={Akagera_transit_lodge_9}
            alt="rwandair"
          />
        </Fade>
      </div>
      <br />
      {/* End 13 days tour*/}

      {/* Start 14 days tour*/}
      <br />
      <h3>Day 14 - DEPARTURE</h3>
      <Paragraph>
        We will wake up in the morning for breakfast as usual after breakfast we
        will get enough moment of preparing our stuffs and relax as we will be
        waiting for the flight at night. In the evening, we will dive to Kigali
        at international airport for flight.
      </Paragraph>
      <br />
      <br />
      <div className="grid grid-cols-1 gap-2">
        <Fade bottom>
          <LazyLoadImage effect="blur" src={Rwandair} alt="rwandair" />
        </Fade>
      </div>
      <br />

      {/* End 14 days tour*/}

      <button onClick={() => openBooking()}>BOOK NOW</button>
    </div>
  );
}
