import GoldenMonkey from "../Pages/activity/Images/goldenMonkey_tracking1.jpg";
import Leopard from "../Pages/activity/Images/leopard.jpg";
import Lion from "../Images/sl (16).jpg";
import Gori from "../Images/-1969662717.jpg";
import Gillafe from "../Images/-981204329.jpg";

export const packages = [
  {
    image: GoldenMonkey,
    title: "3 Days in Rwanda",
    description: (
      <p>Plan your Rwanda Trip with us to make your Dreams come true.</p>
    ),
    link: "/3daystour",
  },
  {
    image: Gori,
    title: "5 Days in Rwanda",
    description: (
      <p>Plan your Rwanda Trip with us to make your Dreams come true.</p>
    ),
    link: "/5days-in-rwanda",
  },
  {
    image: Gillafe,
    title: "5 Days in Uganda",
    description: (
      <p>Plan your Rwanda Trip with us to make your Dreams come true.</p>
    ),
    link: "/5days-in-uganda",
  },
  {
    image: Leopard,
    title: "9 Days in Rwanda & Uganda",
    description: (
      <p>Plan your Rwanda Trip with us to make your Dreams come true.</p>
    ),
    link: "/9daystour",
  },
  {
    image: Lion,
    title: "14 Days in Rwanda",
    description: (
      <p>Plan your Rwanda Trip with us to make your Dreams come true.</p>
    ),
    link: "/14daystour",
  },
];
