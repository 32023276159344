import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import "./book.css";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -30%)",
  width: 380,
  bgcolor: "white",
  border: "2px solid rgba(0,255,0,.1)",
  borderRadius: "7px",
  p: 4,
};

export default function Book(props) {
  const [names, setNames] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setTelephone] = React.useState("Not given");
  const [country, setCountry] = React.useState("");
  const [city, setCity] = React.useState("");
  const [prefix, setprefix] = React.useState("Mr");
  const [nOfPeople, setnOfPeople] = React.useState(null);
  const [budget, setbudget] = React.useState("");
  const [accomodation, setaccomodation] = React.useState("");
  const [tour, setTour] = React.useState(props.selected);
  const [prefActivities, setprefActivities] = React.useState([]);
  const [date, setdate] = React.useState(1);
  const [month, setmonth] = React.useState("January");
  const [loading, setloading] = React.useState(false);

  var preferredActivites = [
    "Gorilla Tracking",
    "Chimpanzee tracking",
    "Nature tours",
    "Hiking mountains",
    "Wildlife viewing",
    "Primate Safaris",
    "Camping",
    "Birdwatching",
    "Culture activities",
  ];

  var message = {
    names,
    email,
    phone,
    country,
    city,
    tour,
    nOfPeople,
    prefix,
    budget,
    accomodation,
    date,
    month,
  };

  var days = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ];
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const submitHandler = async (e) => {
    e.preventDefault();
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `messages`, message)
      .then((data) => {
        alert("Thank you! Your request has been sent successfully.");

        props.handleClose();
      })
      .catch((err) => alert("Sorry. An unkwown problem occured, try again!"));
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.book}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableScrollLock={true}
        sx={{ maxHeight: "100vh", overflowY: "auto" }}
      >
        <Fade in={props.book}>
          <Box sx={style}>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
              style={{ fontWeight: "bold" }}
            >
              Book now
            </Typography>
            <br />
            <form className="bookform" onSubmit={submitHandler}>
              <input
                type="text"
                placeholder="Enter your names"
                required
                onInput={(e) => setNames(e.target.value)}
              />
              <select
                required
                name="prefix"
                id="prefix"
                onInput={(e) => setprefix(e.target.value)}
                defaultValue="Mr"
              >
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
              </select>
              <input
                type="email"
                placeholder="Enter your email"
                required
                onInput={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                placeholder="Telephone (optional)"
                pattern="[0-9]{3,20}"
                title="Sholud conatin only numbers"
                onInput={(e) => setTelephone(e.target.value)}
              />
              <input
                type="text"
                placeholder="Country"
                required
                onInput={(e) => setCountry(e.target.value)}
              />
              <input
                type="text"
                placeholder="State / City"
                required
                onInput={(e) => setCity(e.target.value)}
              />
              <select
                name="tour"
                id="tourSelect"
                onInput={(e) => setTour(e.target.value)}
                defaultValue={props.selected}
              >
                <option value="3 days tour">3 days tour</option>
                <option value="4 Days in Volcanoes National Park">
                  4 Days in Volcanoes National Park
                </option>
                <option value="4 Days Mountain Gorilla Tracking">
                  4 Days Mountain Gorilla Tracking
                </option>
                <option value="5 days tour">5 days tour</option>
                <option value="5 Days in Volcanoes NP & Akagera NP">
                  5 Days in Volcanoes NP & Akagera NP
                </option>
                <option value="5 Days Gorilla Tracking in Uganda">
                  5 Days Gorilla Tracking in Uganda
                </option>
                <option value="6 days tour">6 days tour</option>
                <option value="7 days tour">7 days tour</option>
                <option value="7 Days Primate Safari and Game drive">
                  7 Days Primate Safari and Game drive
                </option>
                <option value="8 Days in Rwanda & Uganda">
                  8 Days in Rwanda & Uganda
                </option>
                <option value="9 days tour">9 days tour</option>
                <option value="14 days tour">14 days tour</option>
              </select>
              <input
                type="number"
                name="nOfPeople"
                placeholder="Enter number of people"
                id="nOfPeople"
                onInput={(e) => setnOfPeople(e.target.value)}
                required
              />
              <select
                required
                name="accomo"
                id="accomo"
                onInput={(e) => setaccomodation(e.target.value)}
              >
                <option value="">Select accomodation</option>
                <option value="Luxury">Luxury</option>
                <option value="Mid-range">Mid-range</option>
                <option value="Budget">Budget</option>
              </select>
              {/* <label htmlFor="preferredActivites">Preferred activities</label>
              <div id="preferredActivities">
                {preferredActivites &&
                  preferredActivites.map((prefActivity, i) => (
                    <div key={i}>
                      <input
                        type="checkbox"
                        value={prefActivity}
                        id={prefActivity}
                        name={prefActivity}
                        onInput={() => {
                          if (prefActivities.includes(prefActivity)) {
                            setprefActivities(
                              prefActivities.filter(
                                (prefAct) => prefAct !== prefActivity
                              )
                            );
                          } else {
                            var prefActCopy = [...prefActivities, prefActivity];
                            setprefActivities(prefActCopy);
                          }
                          return;
                        }}
                      />
                      <label htmlFor={prefActivity}>{prefActivity}</label>
                    </div>
                  ))}
              </div> */}
              <div>
                <label htmlFor="date">Date &amp; month of travel</label>
                <div className="date_month">
                  <select
                    name="date"
                    id="date"
                    onInput={(e) => setdate(Number(e.target.value))}
                  >
                    {days &&
                      days.map((day, i) => (
                        <option key={i} value={day}>
                          {day}
                        </option>
                      ))}
                  </select>
                  <select
                    name="month"
                    id="month"
                    onInput={(e) => setmonth(e.target.value)}
                  >
                    {months &&
                      months.map((month, i) => (
                        <option key={i} value={month}>
                          {month}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <select
                required
                name="accomo"
                id="accomo"
                onInput={(e) => setbudget(e.target.value)}
              >
                <option value="">Select your budget</option>
                <option value="$ 0 - 1000">$ 0 - 1000</option>
                <option value="$ 1000 - 2000">$ 1000 - 2000</option>
                <option value="$ 2000 - 3000">$ 2000 - 3000</option>
                <option value="$ 3000 - 4000">$ 3000 - 4000</option>
                <option value="$ 4000 - 5000">$ 4000 - 5000</option>
                <option value="$ 5000 - 6000">$ 5000 - 6000</option>
                <option value="$ 7000 or More">$ 7000 or More</option>
              </select>

              <input
                type="submit"
                value="Submit"
                onClick={async (e) => {
                  await setTimeout(
                    () => (e.target.style.disabled = true),
                    4000
                  );
                  e.target.style.disabled = false;
                  setloading(true);
                }}
              />

              {/* {loading? 
               <Box sx={{ display: 'flex' }}>
               <CircularProgress />
               </Box>
              :
              <input
                type="submit"
                value="Submit"
                onClick={async (e) => {
                  await setTimeout(
                    () => (e.target.style.disabled = true),
                    4000
                  );
                  e.target.style.disabled = false;
                  setloading(true);
                }}
              />
              } */}
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
