import React from "react";
import { Fade } from "react-slideshow-image";
import room5 from "../../Pages/explore/Images/hiking.png";
import room3 from "../../Pages/explore/Images/room-3.jpg";
import room from "../../Pages/explore/Images/room.jpg";
import room1 from "../../Pages/explore/Images/room1.jpg";
import room2 from "../../Pages/explore/Images/room2.jpg";
import room4 from "../../Pages/explore/Images/room4.jpg";

import "./Hero.css";

const Hero = (props) => {
  console.log("PROPS here \n", props);

  const slideImages = [
    {
      url: room,

      caption: (
        <p className="text-center">
          <span className="text-4xl">Explore Canopy Walkway</span>
        </p>
      ),
    },
    {
      url: room1,
      caption: (
        <p className="text-center">
          <span className="text-4xl">Explore Rwanda Tradition</span>
        </p>
      ),
    },
    {
      url: room2,
      caption: (
        <p className="text-center">
          <span className="text-4xl">Adventure is Calling</span>
        </p>
      ),
    },
    {
      url: room3,
      caption: (
        <p className="text-center">
          <span className="text-4xl">Explore Rwanda adventure</span>
        </p>
      ),
    },

    {
      url: room5,
      caption: (
        <p className="text-center">
          <span className="text-4xl">The World is Yours to Explore</span>
        </p>
      ),
    },
    {
      url: room4,
      caption: (
        <p className="text-center">
          <span className="text-4xl">Explore Rwanda adventure</span>
        </p>
      ),
    },
  ];

  return (
    <div className="relative slide-container">
      <Fade>
        {slideImages.map((slideImage, index) => (
          <div className="each-slide relative w-full" key={index}>
            <div
              className="relative w-full h-[620px] phone:h-[450px]"
              style={{
                backgroundImage: `url(${slideImage.url})`,
                "background-repeat": "no-repeat",
                "background-position": "center",
                "background-size": "cover",
              }}
            >
              {/* <div className="absolute text-white bottom-20 bg-black/30 py-2 w-full flex items-center justify-center text-2xl font-bold">
                {slideImage.caption}
              </div> */}
            </div>
          </div>
        ))}
      </Fade>
    </div>
  );
};

export default Hero;
