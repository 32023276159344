import loadable from "@loadable/component";
import { AiOutlineRight } from "react-icons/ai";
import Fade from "react-reveal/Fade";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import Slides from "../../Components/Comments/slides/slides";
import Cards from "../../Components/Common/Cards";
import { packages } from "../pakages";
import "./Home.css";
import birdWatching from "./Images/birdWatching.jpg";
import birdflying from "./Images/birdwatNyungwe8.jpg";
import primatesTrackingImage from "./Images/primatesTracking.jpg";
import traditionalDance from "./Images/traditional_dance.jpg";
import walkwayImage from "./Images/walkway.jpg";

const Slider = loadable(() => import("../../Components/slider/Slider"));

const data = packages;

const list = [
  {
    image: primatesTrackingImage,
    title: "Gorilla Tracking",
    description: (
      <p>
        Look into the eyes of a large silverback gorilla and he'll look back
        with a thoughtful, intelligent gaze, mindful that you're another
        individual.
      </p>
    ),
    link: "/explore/volcanoes/gorilla tracking",
  },
  {
    image: walkwayImage,
    title: "Canopy Walkway",
    description: (
      <p>
        Once you get to Nyungwe national park, you will then prepare to join on
        one of the most unbelievable adventures you will ever have, the canopy
        walk.
      </p>
    ),
    link: "/explore/nyungwe/canopy walkway",
  },
  {
    image: birdWatching,
    title: "Bird Watching",
    description: (
      <p>
        The best way to bird watch is to look and listen, because many birds
        have unique songs and calls. You’ll be surprised by how many species you
        already recognize, even if you’ve never gone bird watching before.
      </p>
    ),
    link: "/explore/nyungwe/birdwatching",
  },
  {
    image: traditionalDance,
    title: "Traditional Culture",
    description: (
      <p>
        The culture of Rwanda is varied. Unlike many other countries in Africa,
        Rwanda has <br /> been a unified state, populated by people who share a
        single language and cultural heritage.
      </p>
    ),
    link: "/explore/traditional culture",
  },
];

const Home = () => {
  return (
    <main className="subpixel-antialiased text-base font-light">
      <div className="hero top">
        <Slider />
      </div>
      <Link to="content" smooth>
        <i className="fa fa-caret-down"></i>
      </Link>
      <div className="w-full md:w-4/5 mx-auto my-14 ">
        <div className="block md:flex items-center">
          <img src={birdflying} alt="" className="w-full md:w-[60%] " />
          <div className="md:ml-20 mx-3 md:mx-0">
            <h2 className="text-2xl font-semibold text-primary mb-4">
              Discover a new place each day
            </h2>
            <p className="text-gray text-sm md:text-base leading-6">
              Whether you’re discovering what makes home so special or visiting
              for the first time, there’s much to see and do when you visit
              Rwanda.
            </p>
            <NavLink
              to="explore"
              smooth={true}
              className=" flex items-center bg-primary md:w-[30%] justify-center py-2 text-white mt-4 cursor-pointer px-2 hover:bg-white hover:text-primary ease-in-out duration-300 hover:border"
            >
              Explore now <AiOutlineRight />
            </NavLink>
          </div>
        </div>
      </div>

      <div className="content w-full md:w-4/5 mx-auto mt-4">
        <h1 className="text-2xl font-semibold text-primary mb-4 my-4 uppercase text-center">
          EXPLORE RWANDA TOURISM ATTRACTIONS
        </h1>
        <Cards list={list} />
      </div>

      <Fade bottom>
        <div className="content w-full md:w-4/5 mx-auto mt-10">
          <h1 className="text-2xl font-semibold text-primary mb-4 my-4 text-center">
            Suggested Itineraries
          </h1>

          <div className="my-10">
            <Slides data={data} />
          </div>
        </div>
      </Fade>
    </main>
  );
};

export default Home;
